<template>
  <div class="page-box">
    <div class="banner">
      <img v-if="menuIndex===0" src="./assets/img/banner.png" alt />
      <img v-else-if="menuIndex===1" src="./assets/img/banner2.png" alt />
    </div>
    <div class="new-list">
      <div class="new-list-tab">
        <div
          class="new-list-tab-item"
          v-for="(item,index) in menu"
          :key="index"
          @click="menuIndex = index"
        >
          {{item}}
          <div class="new-list-tab-item-select" :class="index==menuIndex?'select-show':''"></div>
        </div>
      </div>
      <div class="new-list-data" v-if="menuIndex===1">
        <div class="new-list-data-left">
          <div
            class="new-menu-item"
            v-for="(item,index) in navMenu"
            :key="index"
            :class="index==navIndex?'new-menu-item-select':''"
            @click="navIndex=index,getHireList()"
          >{{item.name}}</div>
        </div>
        <div class="new-list-data-right">
          <div class="new-list-data-right-data" v-if="zhaopin_list.length>0">
            <div class="right-data-item" v-for="(item,index) in zhaopin_list" :key="index">
              <div>
                <div class="data-item-row1">
                  <span>{{item.title}}</span>
                  <span>{{item.salary}}</span>
                </div>
                <div class="data-item-row2">
                  <span>{{item.demand}}</span>
                  <span>{{item.qualifucation}}</span>
                </div>
              </div>
              <div class="data-item-row3">
                <span>{{item.number}}</span>
                <span>{{item.area}}</span>
              </div>
            </div>
          </div>
          <div v-else class="new-list-data-right-nodata">
            <img src="./assets/img/nodata.png" alt />
            <span>暂无相关职务</span>
          </div>
          <div class="new-list-data-right-fenye">
            <el-pagination
              v-if="zhaopin_list.length>0"
              style="transform: scale(1.3);"
              background
              layout="prev, pager, next, total"
              prev-text="上一页"
              next-text="下一页"
              :total="zhaopin_total"
              :current-page="solvePage"
              :page-size="solveLimit"
              @current-change="currentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div class="map-box" v-else-if="menuIndex===0">
        <div class="contact-us">
          <div class="contact-us-map">
            <el-amap
              ref="map"
              :vid="'amapDemo'"
              :center="[info.longitude, info.latitude]"
              :zoom="12"
              class="amap-demo"
              style="height: 100%;width: 100%"
            >
              <el-amap-marker :position="[info.longitude, info.latitude]"></el-amap-marker>
            </el-amap>
          </div>
          <div class="contact-us-form">
            <div class="contact-us-form-title">业务咨询</div>
            <div class="contact-us-form-item">
              <input placeholder="称呼" v-model="consultFrom.name" />
            </div>
            <div class="contact-us-form-item">
              <input placeholder="电话" v-model="consultFrom.mobile" />
            </div>
            <div class="contact-us-form-item">
              <input placeholder="邮箱" v-model="consultFrom.email" />
            </div>
            <div class="contact-us-form-item">
              <input placeholder="业务范畴" v-model="consultFrom.business" />
            </div>
            <div class="contact-us-form-item contact-us-form-item2">
              <textarea placeholder="详情描述" v-model="consultFrom.content" />
            </div>
            <div class="contact-us-form-btn" @click="consultSubmit()">提交</div>
            <!-- <div class="contact-us-form-icon">
              <img src="./assets/img/dianhua.png" />
              <img src="./assets/img/youxiang.png" />
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { submit_form } from "@/api/submit";
import { getHire } from "@/api/contact";
export default {
  data() {
    return {
      menu: ["商业咨询", "人才招聘"],
      menuIndex: 0,
      navMenu: [
        { name: "技术研发" },
        { name: "业务运营" },
        { name: "综合管理" }
      ],
      navIndex: 0,
      //地图
      info: {
        latitude: "36.137405",
        longitude: "120.46282"
      },
      consultFrom: {
        name: "",
        mobile: "",
        email: "",
        business: "",
        content: ""
      },
      // 招聘列表
      solvePage: 1,
      solveLimit: 9,
      zhaopin_list: [],
      zhaopin_total: 0
    };
  },

  created() {
    this.getHireList();
  },
  mounted() {},

  methods: {
    consultSubmit() {
      if (this.consultFrom.name == "") {
        return this.$message({
          message: "请输入称呼",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.consultFrom.mobile.length != 11) {
        return this.$message({
          message: "请输入正确电话号",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.consultFrom.email == "") {
        return this.$message({
          message: "请输入邮箱",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.consultFrom.business == "") {
        return this.$message({
          message: "请输入业务范畴",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.consultFrom.content == "") {
        return this.$message({
          message: "请输入详情描述",
          type: "error",
          customClass: "messageIndex"
        });
      }
      submit_form(this.consultFrom).then(res => {
        this.$message({
          message: res.msg,
          type: "sucess",
          customClass: "messageIndex"
        });
        for (let key in this.consultFrom) {
          this.consultFrom[key] = "";
        }
      });
    },
    getHireList() {
      getHire({
        page: this.solvePage,
        limit: this.solveLimit,
        cate: this.navIndex + 1
      }).then(res => {
        this.zhaopin_list = res.data.info;
        this.zhaopin_total = res.data.count;
      });
    },
    currentChange(val) {
      this.solvePage = val;
      this.getHireList();
    }
  }
};
</script>

<style scoped lang="css" src="./assets/css/index.css" />
