import request from "@/utils/request";

// 咨询提交
export function submit_form(data) {
  return request({
    url: "/api/index/submit_form",
    method: "post",
    data,
  });
}
// 申请提交
export function submit_apply(data) {
  return request({
    url: "/api/index/submit_apply",
    method: "post",
    data,
  });
}