import { render, staticRenderFns } from "./page-box.vue?vue&type=template&id=84a5a4d8&scoped=true"
import script from "./page-box.vue?vue&type=script&lang=js"
export * from "./page-box.vue?vue&type=script&lang=js"
import style0 from "./page-box.vue?vue&type=style&index=0&id=84a5a4d8&prod&lang=css"
import style1 from "./assets/css/index.css?vue&type=style&index=1&id=84a5a4d8&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84a5a4d8",
  null
  
)

export default component.exports