<template>
  <div>
    <div class="banner-box">
      <img class="banner" src="../assets/img/detection/banner.png" alt />
      <div class="banner-text">
        <div class="banner-text1">
          力争建成全球首个虚拟现实
          全产业链综合检测平台
        </div>
        <div
          class="banner-text2"
        >Strive to build the globle first comprehensive testing platform for the entire virtual reality industry chain</div>
      </div>
    </div>
    <!-- 检测服务 -->
    <div class="detection-box">
      <div class="detection-action">
        <div class="detection-action-left">
          <div class="detection-action-left-title">检测服务</div>
          <div class="detection-action-left-center">
            构建
            <span>10</span>
            个产品种类
            <span>50</span>
            个指标项目综合检测能力，填补国内空白。
          </div>
          <div class="detection-action-left-bottom">
            打造专业的第三方检测公司，承接虚拟现实领域检验检测业务，申报虚拟现实领域国家、行业、团体标准，申请
            <span>CMA、CNAS</span>等国家检测认证资质，建立与信通院泰尔实验室、中国质量认证中心等机构的合作，提升公司在虚拟现实产品和技术检验检测领域的行业影响力。利用检测平台加快申报国家及国际标准，增强我国在虚拟现实领域的话语权。
          </div>
          <div class="detection-action-left-btn" @click="fromShow=true">
            <div class="detection-action-left-btn-content">检测申请</div>
          </div>
        </div>
        <div class="detection-action-right">
          <img src="../assets/img/detection/server.png" alt />
        </div>
      </div>
    </div>
    <div class="dialog-box" v-if="fromShow">
      <div class="dialog-action">
        <div class="dialog-action-title">
          <span>检测申请</span>
          <i class="el-icon-close" @click="fromShow=false"></i>
        </div>
        <div class="dialog-action-module">
          <i class="el-icon-message"></i>
          联系邮箱：
          <span>margarita.wang@qdvri.com</span>
        </div>
      </div>
    </div>
    <!-- 八大实验室 -->
    <div class="eight-box">
      <span>八大实验室</span>
      <div class="title-bottom"></div>
      <div class="accordion">
        <div
          class="accordion-item"
          v-for="(item,index) in eightList"
          :key="index"
          :class="index==eightIndex?'accordion-item-select':''"
          @mouseenter="eightIndex=index"
        >
          <div class="accordion-item-detail">
            <img :src="item.img" alt />
            <div class="accordion-item-detail-content">
              <img :src="item.icon" alt />
              <span>{{item.title}}</span>
            </div>
          </div>
          <div class="accordion-item-intro" :style="{background:index%2==0?'#7abd27':'#a2d167'}">
            <div class="intro-icon">
              <img :src="item.icon" alt />
            </div>
            <div class="intro-line">
              <div class="intro-line-action"></div>
            </div>
            <span>{{item.title}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 检测成果 -->
    <div class="detection-box">
      <div class="detection-action">
        <div class="detection-action-left">
          <div class="detection-action-left-title">检测成果</div>
          <div
            class="detection-action-left-bottom"
          >为贯彻落实2022年工信部等五部委发布的《虚拟现实与行业应用融合发展行动计划（2022-2026年）》，国家虚拟现实/增强现实产品质量检验检测中心联合国家虚拟现实创新中心（青岛），针对增强现实产品质量开展产品质量检验分析，重点聚焦产品本身显示性能，特别是环境光下的显示性能，覆盖主流消费端产品。报告基于检测数据汇总分析了当前增强现实产品的技术质量水平，对终端产品厂商设计、开发提供有益参考，并指导下一步增强现实的相关标准研制工作。</div>
        </div>
        <div class="detection-action-right">
          <img src="../assets/img/detection/achievement.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fromShow: false,
      eightList: [
        {
          img: require("../assets/img/detection/eight/1.png"),
          icon: require("../assets/img/detection/eight-icon/1.png"),
          title: "光学实验室"
        },
        {
          img: require("../assets/img/detection/eight/2.png"),
          icon: require("../assets/img/detection/eight-icon/2.png"),
          title: "追踪定位实验室"
        },
        {
          img: require("../assets/img/detection/eight/3.png"),
          icon: require("../assets/img/detection/eight-icon/3.png"),
          title: "热研究实验室"
        },
        {
          img: require("../assets/img/detection/eight/4.png"),
          icon: require("../assets/img/detection/eight-icon/4.png"),
          title: "人机工程实验室"
        },
        {
          img: require("../assets/img/detection/eight/5.png"),
          icon: require("../assets/img/detection/eight-icon/5.png"),
          title: "声学实验室"
        },
        {
          img: require("../assets/img/detection/eight/6.png"),
          icon: require("../assets/img/detection/eight-icon/6.png"),
          title: "电磁兼容实验室"
        },
        {
          img: require("../assets/img/detection/eight/7.png"),
          icon: require("../assets/img/detection/eight-icon/7.png"),
          title: "天线实验室"
        },
        {
          img: require("../assets/img/detection/eight/8.png"),
          icon: require("../assets/img/detection/eight-icon/8.png"),
          title: "电子实验室"
        }
      ],
      eightIndex: 0
    };
  },

  created() {},
  mounted() {},

  methods: {}
};
</script>

<style scoped lang="css">
.banner-box {
  position: relative;
  width: 100%;
  height: 806px;
  color: #fff;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner img {
  object-fit: cover;
}
.banner-text {
  position: absolute;
  top: 200px;
  left: 164px;
}
.banner-text1 {
  font-size: 46px;
  width: 552px;
  font-weight: 600;
}
.banner-text2 {
  font-size: 26px;
  width: 683px;
  margin-top: 37px;
}
/* 检测服务 */
.detection-box {
  padding: 82px 146px;
  background-color: #efefef;
}
.detection-action {
  width: 100%;
  padding: 46px 52px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
}
.detection-action-left {
  width: calc(100% - 920px);
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detection-action-left-title {
  font-size: 40px;
  font-weight: 600;
}
.detection-action-left-center {
  font-size: 16px;
  color: #585858;
  margin-top: 33px;
}
.detection-action-left-center span {
  color: #7abd27;
  font-size: 30px;
  font-weight: 600;
}
.detection-action-left-bottom {
  color: #898989;
  margin-top: 52px;
  line-height: 30px;
}
.detection-action-left-bottom span {
  color: #000;
}
.detection-action-left-btn {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
.detection-action-left-btn-content {
  width: 166px;
  height: 44px;
  background: url("../assets/img/detection/phone.png");
  background-size: 100% 100%;
  color: #7abd27;
  display: flex;
  padding-left: 65px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: transform 0.3s;
}
.detection-action-left-btn-content:hover {
  transform: scale(1.1);
}
.detection-action-left-btn-content:active {
  transform: scale(1);
}
.detection-action-right {
  width: 860px;
  height: 555px;
}
/* 八大实验室 */
.eight-box {
  width: 100%;
  background-color: #fff;
  padding: 82px 146px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eight-box > span {
  font-size: 40px;
  font-weight: 600;
}
.title-bottom {
  margin-top: 20px;
  width: 80px;
  height: 3px;
  background-color: #7abd27;
}
.accordion {
  width: 100%;
  margin-top: 50px;
  height: 435px;
  display: flex;
  gap: 15px;
}
.accordion-item {
  width: 106px;
  height: 100%;
  transition: width 0.3s ease-in;
  position: relative;
  border-radius: 13px;
  overflow: hidden;
}
.accordion-item-detail {
  position: absolute;
  width: 100%;
  height: 100%;
}
.accordion-item-detail img {
  position: absolute;
  object-fit: cover;
}
.accordion-item-detail-content {
  position: absolute;
  left: 50px;
  bottom: 50px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.accordion-item-detail-content span {
  position: relative;
  margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
}
.accordion-item-detail-content img {
  position: relative;
  width: 37px;
  height: auto;
}
.accordion-item-intro {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  padding: 45px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.intro-icon img {
  width: 37px;
  height: auto;
}
.intro-line {
  flex: 1;
  padding: 20px 0;
}
.intro-line-action {
  height: 100%;
  border-left: 1px solid #fff;
}
.accordion-item-intro span {
  writing-mode: vertical-rl;
  color: #fff;
  font-size: 20px;
}
.accordion-item-select {
  width: calc(100% - (120.5px * 7));
  height: 100%;
}
.accordion-item-select .accordion-item-intro {
  opacity: 0;
}
.dialog-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000006a;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-action {
  width: 490px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.dialog-action-title {
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #daded7;
  font-size: 20px;
  padding: 0 20px;
}
.dialog-action-title i {
  cursor: pointer;
}
.dialog-action-module {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #666;
  padding-bottom: 50px;
}
.dialog-action-module i,
.dialog-action-module span {
  color: #7abd27;
  margin: 0 5px;
}
.dialog-action-module i {
  font-size: 30px;
}
</style>
