import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  namespaced: true,
  actions: {},
  mutations: {
    // 实时屏幕宽度
    setWidth(state, value) {
      state.clientWidth = value
    },
    // 实时屏幕顶部距离
    setTop(state, value) {
      state.clientTop = value
    },
  },
  state: {
    clientWidth: 0,
    clientTop: 0,
  },
});

