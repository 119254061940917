<template>
  <div class="new-swiper">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="item in swiperList" :key="item.id" class="swiper-slide">
          <div class="new-img">
            <img :src="$baseUrl+item.image" />
          </div>
          <div class="new-time">{{dateConvert(item.createtime)}}</div>
          <div class="new-msg">{{item.title}}</div>
        </div>
      </div>
      <div class="swiper-pagination">
        <span class="dangqian"></span>
        <span>/</span>
        <span class="zongshu"></span>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  props: ["swiperList"],
  data() {
    return {};
  },

  created() {},
  mounted() {
    this.getSwiper();
  },

  methods: {
    getSwiper() {
      // 新闻动态模块
      new Swiper(".new-swiper .swiper-container", {
        loop: true,
        autoplay: false,
        paginationClickable: true,
        slidesPerView: 4, // 一组四个
        spaceBetween: "1.5%", // 间隔
        navigation: {
          nextEl: ".new-swiper .swiper-button-next",
          prevEl: ".new-swiper .swiper-button-prev"
        },
        observer: true,
        observeParents: true,
        pagination: {
          el: ".new-swiper .swiper-pagination",
          type: "custom",
          renderCustom: function(swiper, current, total) {
            var dangqian = document.querySelector(".new-swiper .dangqian");
            var zongshu = document.querySelector(".new-swiper .zongshu");
            if (current <= 9) {
              current = "0" + current;
            }
            if (total <= 9) {
              total = "0" + total;
            }
            dangqian.innerText = current;
            zongshu.innerText = total;
          }
        }
      });
    },
    dateConvert(tampNum) {
      var timestamp = tampNum * 1000; // 假设这是一个时间戳
      var date = new Date(timestamp);
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，注意月份是从0开始的，所以需要加1
      var day = date.getDate(); // 获取日期
      // 将年月日格式化为 yyyy-MM-dd 格式
      var formattedDate =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);
      return formattedDate;
    }
  }
};
</script>

<style scoped lang="css">
.new-swiper {
  width: calc(100% - 210px);
  height: 100%;
}

.new-swiper .swiper-container {
  width: 100%;
  height: 100%;
  padding-bottom: 140px;
}

.new-swiper .swiper-button-prev {
  position: absolute;
  top: auto;
  left: -20px;
  right: auto;
  bottom: 0;
  width: 100px;
  height: 100px;
  border: 6px solid #d6d6d6;
  border-radius: 50%;
  color: #8bb652;
  transform: scale(0.6);
}

.new-swiper .swiper-button-next {
  position: absolute;
  top: auto;
  left: 160px;
  bottom: 0px;
  width: 100px;
  height: 100px;
  border: 6px solid #d6d6d6;
  border-radius: 50%;
  color: #8bb652;
  transform: scale(0.6);
}

.new-swiper .swiper-pagination {
  width: 200px;
  position: absolute;
  left: 20px;
  height: 100px;
  bottom: 0;
  line-height: 100px;
  transform: scale(0.6);
  font-size: 40px;
  color: #565656;
  font-weight: 600;
}

.new-img {
  width: 100%;
  height: 239px;
  border-radius: 16px;
  overflow: hidden;
}

.new-img img {
  object-fit: cover;
  transition: transform 0.3s;
  cursor: pointer;
}

.new-img img:hover {
  transform: scale(1.1);
}

.new-time {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0 5px;
}

.new-msg {
  font-size: 18px;
  color: #040404;
  line-height: 35px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zongshu {
  color: #7abd27;
}
</style>
