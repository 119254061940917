import Vuex from '@/store/index'
const baseSize = 16
function setRem() {
  Vuex.commit('setWidth', document.body.clientWidth)
  // const move = document.body.clientWidth < 768
  const move = false
  const scale = document.documentElement.clientWidth / (move ? 375 : 1920)
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
setRem()
window.onresize = () => {
  setRem()
}