var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"detection-box"},[_c('div',{staticClass:"detection-action"},[_c('div',{staticClass:"detection-action-left"},[_c('div',{staticClass:"detection-action-left-title"},[_vm._v("检测服务")]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"detection-action-left-btn",on:{"click":function($event){_vm.fromShow=true}}},[_c('div',{staticClass:"detection-action-left-btn-content"},[_vm._v("检测申请")])])]),_vm._m(3)])]),(_vm.fromShow)?_c('div',{staticClass:"dialog-box"},[_c('div',{staticClass:"dialog-action"},[_c('div',{staticClass:"dialog-action-title"},[_c('span',[_vm._v("检测申请")]),_c('i',{staticClass:"el-icon-close",on:{"click":function($event){_vm.fromShow=false}}})]),_vm._m(4)])]):_vm._e(),_c('div',{staticClass:"eight-box"},[_c('span',[_vm._v("八大实验室")]),_c('div',{staticClass:"title-bottom"}),_c('div',{staticClass:"accordion"},_vm._l((_vm.eightList),function(item,index){return _c('div',{key:index,staticClass:"accordion-item",class:index==_vm.eightIndex?'accordion-item-select':'',on:{"mouseenter":function($event){_vm.eightIndex=index}}},[_c('div',{staticClass:"accordion-item-detail"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"accordion-item-detail-content"},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(item.title))])])]),_c('div',{staticClass:"accordion-item-intro",style:({background:index%2==0?'#7abd27':'#a2d167'})},[_c('div',{staticClass:"intro-icon"},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_vm._m(5,true),_c('span',[_vm._v(_vm._s(item.title))])])])}),0)]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-box"},[_c('img',{staticClass:"banner",attrs:{"src":require("../assets/img/detection/banner.png"),"alt":""}}),_c('div',{staticClass:"banner-text"},[_c('div',{staticClass:"banner-text1"},[_vm._v(" 力争建成全球首个虚拟现实 全产业链综合检测平台 ")]),_c('div',{staticClass:"banner-text2"},[_vm._v("Strive to build the globle first comprehensive testing platform for the entire virtual reality industry chain")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detection-action-left-center"},[_vm._v(" 构建 "),_c('span',[_vm._v("10")]),_vm._v(" 个产品种类 "),_c('span',[_vm._v("50")]),_vm._v(" 个指标项目综合检测能力，填补国内空白。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detection-action-left-bottom"},[_vm._v(" 打造专业的第三方检测公司，承接虚拟现实领域检验检测业务，申报虚拟现实领域国家、行业、团体标准，申请 "),_c('span',[_vm._v("CMA、CNAS")]),_vm._v("等国家检测认证资质，建立与信通院泰尔实验室、中国质量认证中心等机构的合作，提升公司在虚拟现实产品和技术检验检测领域的行业影响力。利用检测平台加快申报国家及国际标准，增强我国在虚拟现实领域的话语权。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detection-action-right"},[_c('img',{attrs:{"src":require("../assets/img/detection/server.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dialog-action-module"},[_c('i',{staticClass:"el-icon-message"}),_vm._v(" 联系邮箱： "),_c('span',[_vm._v("margarita.wang@qdvri.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-line"},[_c('div',{staticClass:"intro-line-action"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detection-box"},[_c('div',{staticClass:"detection-action"},[_c('div',{staticClass:"detection-action-left"},[_c('div',{staticClass:"detection-action-left-title"},[_vm._v("检测成果")]),_c('div',{staticClass:"detection-action-left-bottom"},[_vm._v("为贯彻落实2022年工信部等五部委发布的《虚拟现实与行业应用融合发展行动计划（2022-2026年）》，国家虚拟现实/增强现实产品质量检验检测中心联合国家虚拟现实创新中心（青岛），针对增强现实产品质量开展产品质量检验分析，重点聚焦产品本身显示性能，特别是环境光下的显示性能，覆盖主流消费端产品。报告基于检测数据汇总分析了当前增强现实产品的技术质量水平，对终端产品厂商设计、开发提供有益参考，并指导下一步增强现实的相关标准研制工作。")])]),_c('div',{staticClass:"detection-action-right"},[_c('img',{attrs:{"src":require("../assets/img/detection/achievement.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }