<template>
  <div class="page">
    <div class="banner-box">
      <video muted="muted" autoplay="autoplay" preload="auto" loop="loop">
        <source src="./assets/img/banner.mp4" type="video/mp4" />
      </video>
      <div class="banner-msg">
        <span class="banner-msg-text1">Focus on building six major platforms</span>
        <div class="banner-msg-text2">
          专注
          <span>六大平台</span>
          打造
        </div>
        <span class="banner-msg-text3">辐射重点行业与机构，提供一站式公共服务</span>
      </div>
    </div>
    <div class="toggle-vessel">
      <div class="toggle-vessel-menu">
        <div
          class="toggle-vessel-menu-item"
          v-for="(item,index) in menuList"
          :key="index"
          :class="index==menuIndex?'toggle-vessel-menu-item-select':''"
          @click="jumpMenu(index)"
        >
          <span>{{item}}</span>
          <div
            class="toggle-vessel-menu-item-tab"
            :class="index==menuIndex?'toggle-vessel-menu-item-tab-select':''"
          ></div>
        </div>
      </div>
      <div class="toggle-vessel-action">
        <ExploitMatter v-if="menuList[menuIndex]=='共性技术研发平台'" />
        <DetectionMatter v-else-if="menuList[menuIndex]=='检测平台'" />
        <PilotplantMatter v-else-if="menuList[menuIndex]=='中试平台'" />
        <ServerMatter v-else-if="menuList[menuIndex]=='技术服务平台'" />
        <CultivateMatter v-else-if="menuList[menuIndex]=='人才培养平台'" />
        <TeamworkMatter v-else-if="menuList[menuIndex]=='国际交流与合作平台'" />
      </div>
    </div>
  </div>
</template>
<script>
import ExploitMatter from "./components/ExploitMatter.vue";
import DetectionMatter from "./components/DetectionMatter.vue";
import PilotplantMatter from "./components/PilotplantMatter.vue";
import ServerMatter from "./components/ServerMatter2.vue";
import CultivateMatter from "./components/CultivateMatter.vue";
import TeamworkMatter from "./components/TeamworkMatter.vue";
export default {
  components: {
    ExploitMatter,
    DetectionMatter,
    PilotplantMatter,
    ServerMatter,
    CultivateMatter,
    TeamworkMatter
  },
  data() {
    return {
      menuList: [
        "共性技术研发平台",
        "检测平台",
        "中试平台",
        "技术服务平台",
        "人才培养平台",
        "国际交流与合作平台"
      ],
      menuIndex: 0
    };
  },

  created() {},
  mounted() {
    if ("menuIndex" in this.$route.query) {
      this.jumpMenu(this.$route.query.menuIndex);
    }
  },

  methods: {
    jumpMenu(index) {
      this.menuIndex = index;
      let box = document.querySelector(".toggle-vessel-action");
      let navHeight1 = document.querySelector(".nav-title-box").clientHeight;
      let navHeight2 = document.querySelector(".toggle-vessel-menu")
        .clientHeight;
      window.scrollTo({
        top: box.offsetTop - navHeight1 - navHeight2,
        behavior: "smooth"
      });
      this.$router.push({ path: "/serverPage", query: { menuIndex: index } });
    }
  }
};
</script>

<style scoped lang="css" src="./assets/css/index.css" />
