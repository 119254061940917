import request from "@/utils/request";

// 获取新闻
export function getNews(data) {
  return request({
    url: "/api/index/get_news",
    method: "post",
    data,
  });
}
// 获取新闻
export function getNewsDetail(data) {
  return request({
    url: "/api/index/get_detail",
    method: "post",
    data,
  });
}
// 获取大型仪器设备开放共享服务
export function getServices(data) {
  return request({
    url: "/api/index/get_services",
    method: "post",
    data,
  });
}