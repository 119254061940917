import request from "@/utils/request";

// 获取职位列表
export function getHire(data) {
  return request({
    url: "/api/index/get_hire",
    method: "post",
    data,
  });
}
