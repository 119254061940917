import request from "@/utils/request";

// 获取专家列表
export function getOldTouSwiper(data) {
  return request({
    url: "/api/index/get_expert",
    method: "post",
    data,
  });
}
// 获取专家列表
export function getOldTouList(data) {
  return request({
    url: "/api/index/get_expert_list",
    method: "post",
    data,
  });
}