<template>
  <div>
    <div class="banner-box">
      <img class="banner" src="../assets/img/server/banner.png" alt />
      <div class="banner-text">
        <div class="banner-text1">
          提供
          <span>技术服务</span>
          ，助力明确重点发展方向，促进产业主体培育壮大
        </div>
        <div class="banner-text2">
          Provide technical services to help clarify key development directions and
          promote the cultivation and growth of industrial entities
        </div>
      </div>
    </div>

    <!-- 技术开发服务 -->
    <div class="exploit-box">
      <img class="exploit" src="../assets/img/server/banner2.png" alt />
      <div class="exploit-text">
        <div class="exploit-text1">技术开发服务</div>
        <div class="title-bottom"></div>
        <div class="exploit-text2">面向产学研用行业单位，提供高水平、高质量的技术开发服务,服务内容包括且不限于技术委托开发、合作开发、工艺开发、中试验证、内容制作等</div>
      </div>
    </div>
    <!-- 技术咨询服务 -->
    <div class="consult-box">
      <img class="consult" src="../assets/img/server/banner3.png" alt />
      <div class="consult-text">
        <div class="consult-text1">技术咨询服务</div>
        <div class="title-bottom"></div>
        <div class="consult-text2">为政府、企业、研究机构、投资机构等提供关于虚拟现实领域技术方案的评估、论证咨询服务。</div>
      </div>
    </div>
    <!-- 大型仪器设备开放共享服务 -->
    <div class="solve-box">
      <div class="solve-text">
        <div class="solve-text1">大型仪器设备开放共享服务</div>
        <div class="title-bottom"></div>
      </div>
      <div class="solve-action">
        <div class="fenye-data">
          <div class="fenye-data-item" v-for="(item,index) in solveList" :key="index">
            <img :src="$baseUrl+item.image" alt />
            <div class="fenye-data-item-concat">
              <span>{{item.title}}</span>
              <div class="fenye-data-item-hide">
                <span>{{item.subtitle}}</span>
                <div class="applyfor-btn" @click="fromShow = true">
                  <img src="../assets/img/server/phone.png" alt />
                  点击申请
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="solve-fenye">
        <el-pagination
          style="transform: scale(1.3);"
          background
          layout="prev, pager, next, total"
          prev-text="上一页"
          next-text="下一页"
          :total="solveTotal"
          :current-page="solvePage"
          :page-size="solveLimit"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 行业应用解决方案 -->
    <div class="solve-box">
      <div class="solve-text">
        <div class="solve-text1">行业应用解决方案</div>
        <div class="title-bottom"></div>
        <div class="solve-text2">深入探索虚拟现实赋能全行业领域的广泛应用前景，发挥平台优势，以新质生产力推动前沿技术成果落地与转化</div>
      </div>
      <div class="solve-action">
        <div class="scheme-box">
          <div class="scheme-item" v-for="(item,index) in schemeList" :key="index">
            <img :src="item.img" alt />
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 表单弹窗 -->
    <div class="dialog-box" v-if="fromShow">
      <div class="dialog-action">
        <div class="dialog-action-title">
          <span>申请仪器</span>
          <i class="el-icon-close" @click="fromShow=false"></i>
        </div>
        <div class="dialog-action-form">
          <div class="dialog-action-form-item">
            <div class="dialog-action-form-item-left">所需设备:</div>
            <div class="dialog-action-form-item-right">
              <input type="text" placeholder="请填写" v-model="fromSubmit.business" />
            </div>
          </div>
          <div class="dialog-action-form-item">
            <div class="dialog-action-form-item-left">公司名称/团队名称:</div>
            <div class="dialog-action-form-item-right">
              <input type="text" placeholder="请填写" v-model="fromSubmit.name" />
            </div>
          </div>
          <div class="dialog-action-form-item">
            <div class="dialog-action-form-item-left">项目名称:</div>
            <div class="dialog-action-form-item-right">
              <input type="text" placeholder="请填写" v-model="fromSubmit.project" />
            </div>
          </div>
          <div class="dialog-action-form-item">
            <div class="dialog-action-form-item-left">需求描述:</div>
            <div class="dialog-action-form-item-right">
              <input type="text" placeholder="请填写" v-model="fromSubmit.content" />
            </div>
          </div>
          <div class="dialog-action-form-item">
            <div class="dialog-action-form-item-left">联系方式:</div>
            <div class="dialog-action-form-item-right">
              <input type="text" placeholder="请填写" v-model="fromSubmit.mobile" />
            </div>
          </div>
          <div class="dialog-action-form-item">
            <div class="dialog-action-form-item-left"></div>
            <div class="dialog-action-form-item-right">
              <div class="dialog-action-form-item-btn">
                <span @click="fromShow=false">取消</span>
                <span @click="submit()">提交</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-box" v-if="resultShow">
      <div class="dialog-action result-box">
        <img src="../assets/img/server/duihao.png" alt />
        <span>提交成功</span>
        <span>我们将尽快与您联系</span>
        <span @click="resultShow=false">确定</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getServices } from "@/api/tuwen";
import { submit_apply } from "@/api/submit";

export default {
  data() {
    return {
      solveList: [],
      solvePage: 1,
      solveLimit: 6,
      solveTotal: 0,
      schemeList: [
        {
          name: "工业生产",
          img: require("../assets/img/server/scheme2/1.png")
        },
        {
          name: "文化旅游",
          img: require("../assets/img/server/scheme2/2.png")
        },
        {
          name: "融合媒体",
          img: require("../assets/img/server/scheme2/3.png")
        },
        {
          name: "教育培训",
          img: require("../assets/img/server/scheme2/4.png")
        },
        {
          name: "体育健康",
          img: require("../assets/img/server/scheme2/5.png")
        },
        {
          name: "商贸创意",
          img: require("../assets/img/server/scheme2/6.png")
        },
        {
          name: "演绎娱乐",
          img: require("../assets/img/server/scheme2/7.png")
        },
        {
          name: "安全应急",
          img: require("../assets/img/server/scheme2/8.png")
        },
        {
          name: "残障辅助",
          img: require("../assets/img/server/scheme2/9.png")
        },
        {
          name: "智慧城市",
          img: require("../assets/img/server/scheme2/10.png")
        }
      ],
      schemeIndex: 0,
      fromShow: false,
      fromSubmit: {
        name: "",
        mobile: "",
        content: "",
        business: "",
        project: ""
      },
      resultShow: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    currentChange(val) {
      this.solvePage = val;
      this.getList();
    },
    getList() {
      getServices({ page: this.solvePage, limit: this.solveLimit }).then(
        res => {
          this.solveList = res.data.info.splice(0, 6);
          this.solveTotal = res.data.count;
        }
      );
    },
    submit() {
      if (this.fromSubmit.business == "") {
        return this.$message({
          message: "请输入所需设备",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.fromSubmit.name == "") {
        return this.$message({
          message: "请输入公司名称/团队名称",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.fromSubmit.project == "") {
        return this.$message({
          message: "请输入项目名称",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.fromSubmit.content == "") {
        return this.$message({
          message: "请输入需求描述",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.fromSubmit.mobile == "") {
        return this.$message({
          message: "请输入联系方式",
          type: "error",
          customClass: "messageIndex"
        });
      }
      submit_apply(this.fromSubmit).then(() => {
        this.fromShow = false;
        this.resultShow = true;
        for (let key in this.fromSubmit) {
          this.fromSubmit[key] = "";
        }
      });
    }
  }
};
</script>

<style scoped lang="css">
.banner-box {
  position: relative;
  width: 100%;
  height: 797px;
  color: #000;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner img {
  object-fit: cover;
}
.banner-text {
  position: absolute;
  top: 200px;
  left: 164px;
}
.banner-text1 {
  font-size: 32px;
  width: 671px;
  font-weight: 600;
}
.banner-text1 span {
  color: #7abd27;
}
.banner-text2 {
  font-size: 20px;
  width: 683px;
  margin-top: 37px;
  opacity: 0.58;
}

.title-bottom {
  margin-top: 20px;
  width: 80px;
  height: 3px;
  background-color: #7abd27;
}
/* 行业应用解决方案  && 大型仪器设备开放共享服务 公共区域*/
.solve-box {
  position: relative;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 95px 0 0;
}
.solve-text {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.solve-text1 {
  font-size: 32px;
  font-weight: 600;
}
.solve-text2 {
  font-size: 20px;
  margin-top: 37px;
  opacity: 0.58;
}
.solve-action {
  margin-top: 60px;
  width: 100%;
  padding: 0 180px;
}
/* 行业应用解决方案 */
.scheme-box {
  width: 100%;
  height: 593px;
  display: flex;
  flex-wrap: wrap;
  gap: 38px;
  padding-bottom: 88px;
}
.scheme-item {
  width: calc((100% - 4 * 38px) / 5);
  height: calc((100% - 38px) / 2);
  box-shadow: 0px 6px 13px 0px rgba(88, 88, 88, 0.22);
  border-radius: 6px;
  border: 1px solid #eaeaea;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.scheme-item:hover {
  color: #7abd27;
  font-weight: 600;
}
.scheme-item img {
  width: auto;
  height: 63px;
  margin-bottom: 28px;
}

/* 大型仪器设备开放共享服务 */
.fenye-data {
  width: 100%;
  height: 688px;
  display: flex;
  flex-wrap: wrap;
  gap: 44px;
}
.fenye-data-item {
  width: calc((100% - 88px) / 3);
  height: calc((100% - 44px) / 2);
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.fenye-data-item img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.fenye-data-item:hover .fenye-data-item-concat {
  height: 213px;
}
.fenye-data-item:hover .fenye-data-item-concat > span {
  height: 50px;
}
.fenye-data-item:hover .fenye-data-item-hide {
  opacity: 1;
}
.fenye-data-item-concat {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  transition: height 0.3s;
}
.fenye-data-item-concat > span {
  font-size: 30px;
  font-weight: 600;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  color: #fff;
  transition: height 0.3s;
}
.fenye-data-item-hide {
  width: 100%;
  height: 143px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  transition: opacity 0.5s;
  opacity: 0;
}
.applyfor-btn {
  position: relative;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: #000;
  margin-top: 35px;
}
.applyfor-btn img {
  position: relative;
  width: 27px;
  height: 27px;
  margin-right: 10px;
}

.solve-fenye {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 技术开发服务 */
.exploit-box {
  position: relative;
  width: 100%;
  height: 830px;
  color: #000;
}
.exploit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.exploit img {
  object-fit: cover;
}
.exploit-text {
  position: absolute;
  top: 300px;
  left: 1052px;
  color: #fff;
}
.exploit-text1 {
  font-size: 32px;
  font-weight: 600;
}
.exploit-text2 {
  font-size: 20px;
  width: 560px;
  margin-top: 37px;
  opacity: 0.58;
}
/* 技术咨询服务 */
.consult-box {
  position: relative;
  width: 100%;
  height: 830px;
  color: #000;
}
.consult {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.consult img {
  object-fit: cover;
}
.consult-text {
  position: absolute;
  top: 146px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.consult-text1 {
  font-size: 32px;
  font-weight: 600;
}
.consult-text2 {
  font-size: 20px;
  margin-top: 37px;
  opacity: 0.58;
}
.dialog-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000006a;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-action {
  width: 674px;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
}
.dialog-action-title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  font-size: 20px;
}
.dialog-action-title i {
  cursor: pointer;
}
.dialog-action-form {
  width: 100%;
  padding: 30px 30px 0 0;
}
.dialog-action-form-item {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.dialog-action-form-item-left {
  width: 160px;
  display: flex;
  justify-content: flex-end;
  color: #4b555e;
}
.dialog-action-form-item-right {
  width: calc(100% - 200px);
  height: 100%;
}
.dialog-action-form-item-right input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #c3c4c8;
  padding: 0 20px;
  font-size: 16px;
}
.dialog-action-form-item-btn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.dialog-action-form-item-btn span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}
.dialog-action-form-item-btn :nth-child(1) {
  border: 1px solid #7abd27;
  color: #7abd27;
}
.dialog-action-form-item-btn :nth-child(2) {
  background-color: #7abd27;
  color: #fff;
  margin-left: 20px;
}
.result-box {
  width: auto;
  padding: 60px 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.result-box img {
  width: 74px;
  height: 74px;
}
.result-box :nth-child(2) {
  margin-top: 31px;
  font-size: 24px;
  font-weight: 600;
}
.result-box :nth-child(3) {
  margin-top: 28px;
  font-size: 16.5px;
}
.result-box :nth-child(4) {
  margin-top: 34px;
  font-size: 16.5px;
  width: 144px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7abd27;
  border: 1px solid #7abd27;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
}
</style>