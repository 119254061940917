<template>
  <div class="page">
    <div id="section1">
      <img src="./assets/img/banner.png" />
      <div class="banner-msg">
        <span>
          构建覆盖虚拟现实
          <br />全产业链的产业创新生态
        </span>
        <span>推动国家虚拟现实产业高质量发展</span>
      </div>
    </div>
    <!-- <div class="section-merge"> -->
    <div id="section2">
      <span>专家团队</span>
      <div class="title-bottom"></div>
      <div class="team-swiper">
        <SpecialistSwiper :oldtouList="oldtouList" v-if="oldtouList.length>0" />
      </div>
      <div class="team-member">
        <div class="team-member-item" v-for="(item,index) in oldtouList2.slice(0, 18)" :key="index">
          <div class="team-member-item-green">
            <div class="team-member-item-green-child"></div>
          </div>
          <div class="team-member-item-action">
            <div class="team-member-item-action-name">
              <span>{{item.title}}</span>
              {{item.subtitle}}
            </div>
            <span>{{item.abstract}}</span>
          </div>
        </div>
      </div>
    </div>
    <div id="section3">
      <span>科研合作</span>
      <div class="title-bottom"></div>
      <div class="hatch-text">与国内虚拟现实领域仅有的两个国家级实验室建立深度合作关系，共同开展重大课题研究。</div>
      <div class="scientific-research">
        <div class="scientific-research-item">
          <span>“虚拟现实/增强现实技术及应用国家工程实验室”</span>
          <span>以高沉浸感、多感知多模式交互的 VR/AR 内容开发平台和 VR/AR 系统与设备为目标，着重突破多模传感、数据建模、图像处理、触觉反馈等关键技术和工程方法，提升我国 VR/AR 全产业链的竞争实力。</span>
        </div>
        <div class="scientific-research-item">
          <span>“虚拟现实技术与系统全国重点实验室”</span>
          <span>实验室总体定位于虚拟现实的应用基础与核心技术研究，强调原始创新、重视系统研发，发挥实验室多学科交叉的优势，为虚拟现实技术的发展和应用做出基础性、示范性、引领性贡献。</span>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <div id="section4">
      <span>平台资质</span>
      <div class="title-bottom"></div>
      <CertificateSwiper />
    </div>
    <div id="section5">
      <span>企业孵化</span>
      <div class="title-bottom"></div>
      <div class="hatch-text">创新中心汇聚各方资源，打破行业壁垒，助力企业孵化，促进虚拟现实产业生态健康发展</div>
      <div class="hatch-action">
        <div class="hatch-action-item">
          <div class="hatch-action-item-vessel">
            <div class="item-vessel-fan">
              <img src="./assets/img/qyfh.png" alt />
            </div>
            <div class="item-vessel-zheng">
              <img src="./assets/img/gclj.png" alt />
              <div class="hatch-action-item-title">青岛国创灵境检验检测有限公司</div>
              <div
                class="hatch-action-item-text"
              >打造专业的第三方检测公司，承接虚拟现实领域检验检测业务，申报虚拟现实领域国家、行业、团体标准，申请CMA、CNAS等国家检测认证资质，建立与信通院泰尔实验室、中国质量认证中心等机构的合作，提升公司在虚拟现实产品和技术检验检测领域的行业影响力。</div>
            </div>
          </div>
        </div>
        <div class="hatch-action-item">
          <div class="hatch-action-item-vessel">
            <div class="item-vessel-fan">
              <img src="./assets/img/qyfh2.png" alt />
            </div>
            <div class="item-vessel-zheng">
              <img src="./assets/img/gclj2.png" alt />
              <div class="hatch-action-item-title">青岛瑞艾勒迪科技有限公司</div>
              <div class="hatch-action-item-text">
                由国家虚拟现实创新中心(青岛)孵化的一家高科技公司，于2022年成立于山东青岛。
                公司旨在依托于虚拟现实、人工智能、大数据、5G等相关技术，驱动体育教育、培训赛事、文旅等领域数字化与智慧化的进程。公司汇聚顶尖资源，深度聚焦体育教育与专业训练，打造VR数智体教融合平台，全方位服务于中小学体育教学活动。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="section6">
      <span>合作联盟</span>
      <div class="title-bottom"></div>
      <div
        class="hatch-text"
      >国创中心（青岛）与中国虚拟现实技术与产业创新平台建立了深度合作共建关系，联盟成员单位包括虚拟现实相关领域企业、大专院校、科研机构、社会团体、政府及投资机构等相关企事业单位三百余家。</div>
      <div class="turntable-container">
        <div class="turntable-action">
          <img src="./assets/img/zhuanpan.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SpecialistSwiper from "./components/SpecialistSwiper.vue";
import CertificateSwiper from "./components/CertificateSwiper.vue";
import { getOldTouSwiper, getOldTouList } from "@/api/oldtou";
export default {
  components: { SpecialistSwiper, CertificateSwiper },
  data() {
    return {
      oldtouList: [],
      oldtouList2: []
    };
  },

  created() {
    getOldTouSwiper().then(res => {
      this.oldtouList = res.data.info;
    });
    getOldTouList().then(res => {
      this.oldtouList2 = res.data.info;
    });
  },
  mounted() {},

  methods: {}
};
</script>

<style scoped lang="css" src="./assets/css/index.css" />
