<template>
  <div class="swiper-box">
    <div class="swiper-box-action">
      <div class="swiper-box-left">
        <span>平</span>
        <span>台</span>
        <span>资</span>
        <span>质</span>
      </div>
      <div class="swiper-box-right">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="swiper-slide-left">
                <span>Platform Qualification</span>
                <span>基于沉浸影音开发工具链的虚拟现实融合应用创新公共服务平台</span>
                <span>工业和信息化部</span>
                <div class="line-box">
                  <div class="line"></div>
                </div>
              </div>
              <div class="swiper-slide-right">
                <img src="../assets/img/platform/2.png" alt />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper-slide-left">
                <span>Platform Qualification</span>
                <span>虚拟现实共性技术文化和旅游部技术创新中心</span>
                <span>文化和旅游部</span>
                <div class="line-box">
                  <div class="line"></div>
                </div>
              </div>
              <div class="swiper-slide-right">
                <img src="../assets/img/platform/1.png" alt />
              </div>
            </div>
            <div class="swiper-slide swiper-slide-bg">
              <div class="swiper-slide-left">
                <span></span>
                <span style="width:150%;">
                  创新中心以丰富资源推动产业科技创新、发展新质生产力，形成跨领域、强协作
                  的高质量协同创新共同体。打造一系列集课题孕育，项目孵化，成果转化于一体
                  的公共服务平台
                </span>
                <span></span>
                <div class="line-box">
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next-certificate">
      <i class="el-icon-arrow-right"></i>
    </div>
    <div class="swiper-button-prev-certificate">
      <i class="el-icon-arrow-left"></i>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {};
  },

  created() {},
  mounted() {
    this.getSwiper();
  },

  methods: {
    getSwiper() {
      new Swiper(".swiper-box-right .swiper-container", {
        autoplay: false,
        loop: true,
        paginationClickable: true,
        slidesPerView: 1, // 一组1个
        spaceBetween: "0%", // 间隔
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next-certificate",
          prevEl: ".swiper-button-prev-certificate"
        },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true
      });
    }
  }
};
</script>

<style scoped lang="css">
.swiper-box {
  margin-top: 53px;
  width: 100%;
  height: 716px;
  position: relative;
  padding: 0 134px;
}

.swiper-box .swiper-box-action {
  width: 100%;
  height: 100%;
  background-color: #8fc31f;
  display: flex;
  border-radius: 30px;
}

.swiper-box-left {
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
  color: #fff;
}

.swiper-box-right {
  width: calc(100% - 50px);
  height: 100%;
  background-color: #fff;
  border-radius: 25px;
}
.swiper-button-next-certificate {
  top: calc(50% - 30px);
  right: 0px;
}
.swiper-button-prev-certificate {
  top: calc(50% - 30px);
  left: 0px;
}
.swiper-button-next-certificate,
.swiper-button-prev-certificate {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 4px solid #d6d6d6;
  border-radius: 50%;
  color: #8bb652;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.swiper-box i {
  display: flex;
  align-items: center;
}
.swiper-button-next-certificate:hover,
.swiper-button-prev-certificate:hover {
  transform: scale(1.01);
}

.swiper-button-next-certificate:active,
.swiper-button-prev-certificate:active {
  transform: scale(0.99);
}

.swiper-box .swiper-container {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.swiper-box .swiper-slide {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  padding: 160px 90px;
}
.swiper-slide-bg {
  background: url("../assets/img/platform/3-bg.png");
  background-size: 100% 100%;
}
.swiper-slide-left {
  width: calc(100% - 636px);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.swiper-slide-left :nth-child(1) {
  font-size: 40px;
  font-weight: 600;
  color: #dddddd;
}

.swiper-slide-left :nth-child(2) {
  font-size: 30px;
  font-weight: 600;
  margin-top: 32px;
}

.swiper-slide-left :nth-child(3) {
  font-size: 20px;
  margin-top: 24px;
}

.swiper-box {
  margin-top: 53px;
  width: 100%;
  height: 716px;
  position: relative;
  padding: 0 134px;
}

.swiper-slide-right {
  width: 586px;
  height: 100%;
}

.swiper-slide-right img {
  object-fit: cover;
}

.line-box {
  margin-top: 100px;
  width: 100%;
  height: 4px;
  background-color: #e8e8e8;
}

.line-box .line {
  width: 90px;
  height: 100%;
  background-color: #7abd27;
}
</style>
