<template>
  <div class="specialist-box">
    <div class="swiper-container">
      <div class="swiper-wrapper swiper-no-swiping">
        <div class="swiper-slide" v-for="(item,index) in list" :key="index">
          <div class="swiper-slide-img">
            <img :src="$baseUrl+item.image" alt />
          </div>
          <div class="member-msg">
            <span>{{item.title}}</span>
            <span>{{item.subtitle}}</span>
            <span>{{item.abstract}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-prev-specialist" @click="btnRestrict++" v-show="btnRestrict<2">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="swiper-button-next-specialist" @click="btnRestrict--" v-show="btnRestrict>-2">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css";
export default {
  props: ["oldtouList"],
  data() {
    return {
      list: [],
      btnRestrict: 0
    };
  },

  created() {
    this.list = this.oldtouList;
  },
  mounted() {
    this.getSwiper();
  },

  methods: {
    getSwiper() {
      let mySwiper = new Swiper(".specialist-box .swiper-container", {
        autoplay: false,
        loop: true,
        centeredSlides: true,
        paginationClickable: true,
        slidesPerView: 5, // 一组1个
        spaceBetween: "0%", // 间隔
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next-specialist",
          prevEl: ".swiper-button-prev-specialist"
        },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        onSlideChangeEnd: swiper => {
          swiper.update();
          mySwiper.startAutoplay();
          mySwiper.reLoop();
        }
      });
    }
  }
};
</script>

<style scoped lang="css">
.specialist-box {
  height: 100%;
  width: 100%;
  position: relative;
}
.swiper-container {
  width: 100%;
  height: 100%;
  padding-bottom: 147px;
}

.swiper-slide {
  width: 100%;
  height: 100%;
}
.swiper-slide-img {
  border-radius: 50%;
}
.swiper-slide > .swiper-slide-img img {
  width: 100%;
  height: auto;
  transform: scale(0.65);
  transition: transform 0.3s;
}
.swiper-slide-img img:hover {
  transform: scale(0.8);
}
.swiper-slide-active > .swiper-slide-img {
  border: 1px dashed #8bb652;
}
.swiper-slide-active > .swiper-slide-img img {
  transform: scale(0.8);
}
.swiper-slide-active > .swiper-slide-img img:hover {
  transform: scale(0.85);
}
.swiper-slide .member-msg {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 100%) scale(0);
  width: 150%;
  height: 150px;
  opacity: 0;
}
.swiper-slide-active .member-msg {
  opacity: 1;
  transform: translate(-50%, 100%) scale(1);
}
.member-msg {
  transition: opacity 0.3s, transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.member-msg :nth-child(1) {
  font-size: 30px;
  font-weight: 600;
}
.member-msg :nth-child(2) {
  font-size: 24px;
  margin-top: 10px;
}
.member-msg :nth-child(3) {
  font-size: 20px;
  margin-top: 10px;
}

.swiper-button-next-specialist {
  bottom: 50px;
  right: calc(50% - 300px);
}
.swiper-button-prev-specialist {
  bottom: 50px;
  left: calc(50% - 300px);
}
.swiper-button-next-specialist,
.swiper-button-prev-specialist {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 4px solid #d6d6d6;
  border-radius: 50%;
  color: #8bb652;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 100;
  cursor: pointer;
}
.swiper-button-next-specialist:hover,
.swiper-button-prev-specialist:hover {
  transform: scale(1.01);
}

.swiper-button-next-specialist:active,
.swiper-button-prev-specialist:active {
  transform: scale(0.99);
}
.specialist-box i {
  display: flex;
  align-items: center;
}
</style>
