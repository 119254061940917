import { render, staticRenderFns } from "./server-page.vue?vue&type=template&id=6b37e5dc&scoped=true"
import script from "./server-page.vue?vue&type=script&lang=js"
export * from "./server-page.vue?vue&type=script&lang=js"
import style0 from "./assets/css/index.css?vue&type=style&index=0&id=6b37e5dc&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b37e5dc",
  null
  
)

export default component.exports