<template>
  <div class="page-box">
    <div class="banner">
      <img src="./assets/img/banner.png" alt />
    </div>
    <div class="new-list">
      <div class="new-list-tab">
        <div
          class="new-list-tab-item"
          v-for="(item,index) in menu"
          :key="index"
          @click="menuIndex = index,getFun()"
        >
          {{item}}
          <div class="new-list-tab-item-select" :class="index==menuIndex?'select-show':''"></div>
        </div>
      </div>
      <div class="new-list-data" v-for="(item,index) in list" :key="index">
        <div class="new-list-data-left">
          <span>{{dateConvert(item.createtime)[2]}}</span>
          <span class="interval">/</span>
          <div>
            <span>{{dateConvert(item.createtime)[1]}} 月</span>
            <span>{{dateConvert(item.createtime)[0]}}</span>
          </div>
        </div>
        <div class="new-list-data-right">
          <div class="new-list-data-right-msg">
            <span>{{item.title}}</span>
            <span>{{item.abstract}}</span>
            <span @click="jumpDetail(item.id)">查看详情</span>
          </div>
          <div class="new-list-data-right-img">
            <img :src="$baseUrl+item.image" alt />
          </div>
        </div>
      </div>
      <div class="new-list-fenye">
        <el-pagination
          style="transform: scale(1.3);"
          background
          layout="prev, pager, next, total"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          :current-page="page"
          :page-size="limit"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getNews } from "@/api/tuwen";
export default {
  data() {
    return {
      menu: ["中心动态", "领导关怀", "通知公告"],
      menuIndex: 0,
      list: [],
      total: 0,
      page: 1,
      limit: 10
    };
  },

  created() {
    this.getFun(0);
  },
  mounted() {},

  methods: {
    jumpDetail(id) {
      this.$router.push({ path: "/newDetailPage", query: { id } });
      window.scrollTo(0, 0);
    },
    currentChange(val) {
      this.page = val;
      this.getFun();
    },
    getFun() {
      getNews({
        page: this.page,
        limit: this.limit,
        cid: this.menuIndex + 1
      }).then(res => {
        this.list = res.data.info;
        this.total = res.data.count;
      });
    },
    dateConvert(tampNum) {
      var timestamp = tampNum * 1000; // 假设这是一个时间戳
      var date = new Date(timestamp);
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，注意月份是从0开始的，所以需要加1
      var day = date.getDate(); // 获取日期
      // 将年月日格式化为 yyyy-MM-dd 格式
      var formattedDate =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);
      return formattedDate.split("-");
    }
  }
};
</script>

<style scoped lang="css" src="./assets/css/index.css" />
