import { render, staticRenderFns } from "./contact-page.vue?vue&type=template&id=6a2fdbbc&scoped=true"
import script from "./contact-page.vue?vue&type=script&lang=js"
export * from "./contact-page.vue?vue&type=script&lang=js"
import style0 from "./assets/css/index.css?vue&type=style&index=0&id=6a2fdbbc&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a2fdbbc",
  null
  
)

export default component.exports