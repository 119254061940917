<template>
  <div>
    <div class="banner-box">
      <img class="banner" src="../assets/img/pilotplant/banner.png" alt />
      <div class="banner-text">
        <div class="banner-text1">
          提升虚拟现实产品设计验证及迭代优化能力
          <br />畅通中小企业批量试制“断点”与“堵点”
        </div>
        <div class="banner-text2">
          Enhance the capability of virtual reality product design validation and
          iterative optimizationSmooth the "breakpoints" and "bottlenecks" of
          batch trial production for small and medium-sized enterprises
        </div>
      </div>
    </div>
    <div class="banner-box">
      <img class="banner" src="../assets/img/pilotplant/banner2.png" alt />
      <div class="banner-text">
        <div class="banner-text1">场地规模</div>
        <div class="title-bottom"></div>
        <div class="banner-text2">
          <div class="banner-text2-highlight">
            <span></span> 虚拟现实整机中试线&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span></span> 表面浮雕光波导中试线
          </div>整体规模
          <span>2400</span>
          ㎡，涵盖生产车间与技术测试区域。
        </div>
        <div class="banner-btn" @click="fromShow=true">中试申请</div>
      </div>
    </div>
    <div class="dialog-box" v-if="fromShow">
      <div class="dialog-action">
        <div class="dialog-action-title">
          <span>中试申请</span>
          <i class="el-icon-close" @click="fromShow=false"></i>
        </div>
        <div class="dialog-action-module">
          <i class="el-icon-message"></i>
          联系邮箱：
          <span>margarita.wang@qdvri.com</span>
        </div>
      </div>
    </div>
    <!-- 生产能力 -->
    <div class="detection-box">
      <div class="detection-action">
        <div class="detection-action-title">生产能力</div>
        <div class="detection-action-text">搭建千级无尘车间，面向XR线体的完整生产线，年产可达15万台，可应对多类型产品生产</div>
        <div class="detection-action-li">
          <span></span>
          千级无尘车间满足光学器件组装之需
        </div>
        <div class="detection-action-li">
          <span></span>
          光机与波导双目自动AA,可同步达成A测，极力提升开发效率
        </div>
        <div class="detection-action-li">
          <span></span>
          采用2D/3D高精度视觉引导五轴点胶设备，满足大曲面屏特异形状的点胶场景需求
        </div>
        <div class="detection-action-li">
          <span></span>
          配置激光焊接机，等离子清洗机，可满足XR产品精密零组件的开发
        </div>
        <div class="detection-action-li">
          <span></span>
          MES系统，生产数据可追溯
        </div>
      </div>
    </div>
    <!-- 测试能力 -->
    <div
      class="test-ability"
      :style="{background:`url(${swiperPaging[pagingIndex].img}) no-repeat`}"
    >
      <div class="test-ability-receptacle">
        <div class="test-ability-title">测试能力</div>
        <div class="title-bottom"></div>
        <div class="test-ability-action">
          <div class="test-ability-action-left">
            <div class="test-ability-action-left-title">
              <span>涵盖XR产品所有基础功能测试</span>
              <div class="action-title-line">
                <div class="action-title-line-action"></div>
              </div>
              <div class="test-ability-action-left-text">蓝牙各信道性能测试</div>
              <div class="test-ability-action-left-text">WIFI性能测试（2.4G、5G、6G TXRX）</div>
            </div>
          </div>
          <div class="test-ability-action-right">
            <div class="swiper-handle">
              <div class="swiper-handle-left">
                <div
                  class="swiper-paging"
                  v-for="(item,index) in swiperPaging"
                  :key="index"
                  :class="index==pagingIndex?'swiper-paging-select':''"
                  @click="goSwiper(index)"
                >{{item.name}}</div>
              </div>
              <div class="swiper-handle-right">
                <div class="swiper-handle-prev">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div class="swiper-handle-next">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div class="swiper-action">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item,index) in swiperPaging" :key="index">
                    <div class="swiper-slide-item">
                      <img :src="item.img" alt />
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      fromShow: false,
      mySwiper: null,
      swiperPaging: [
        {
          name: "射频性能测试",
          img: require("../assets/img/pilotplant/swiper/1.jpg")
        },
        {
          name: "传感器测试与校准",
          img: require("../assets/img/pilotplant/swiper/2.jpg")
        },
        {
          name: "光学功能测试及校准",
          img: require("../assets/img/pilotplant/swiper/3.jpg")
        },
        {
          name: "Display部分",
          img: require("../assets/img/pilotplant/swiper/4.jpg")
        }
      ],
      pagingIndex: 0
    };
  },
  mounted() {
    this.getSwiper();
  },

  methods: {
    getSwiper() {
      this.mySwiper = new Swiper(".swiper-action .swiper-container", {
        autoplay: false,
        loop: true,
        paginationClickable: true,
        slidesPerView: 3,
        spaceBetween: "2%",
        navigation: {
          nextEl: ".swiper-handle-next",
          prevEl: ".swiper-handle-prev"
        },
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          type: "custom",
          renderCustom: (swiper, current) => {
            this.pagingIndex = current - 1;
          }
        }
      });
    },
    goSwiper(index) {
      console.log(index);
      // this.pagingIndex = index;
      // this.mySwiper.slideTo(index - 1, 500, false);
    }
  }
};
</script>

<style scoped lang="css">
.banner-box {
  position: relative;
  width: 100%;
  height: 806px;
  color: #000;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner img {
  object-fit: cover;
}
.banner-text {
  position: absolute;
  top: 200px;
  left: 164px;
}
.banner-text1 {
  font-size: 32px;
  width: 671px;
  font-weight: 600;
}
.banner-text2 {
  font-size: 20px;
  width: 683px;
  margin-top: 37px;
  opacity: 0.58;
}
.banner-text2 span {
  color: #7abd27;
  font-weight: 600;
  font-size: 28px;
}
.banner-text2-highlight {
  padding: 5px 10px;
  background-color: #c7d3b5;
  display: flex;
  align-items: center;
  border-radius: 30px;
  color: #457c00;
  width: 510px;
  font-weight: 600;
  margin-bottom: 10px;
}
.banner-text2-highlight span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #457c00;
  margin: 0 10px;
  border-radius: 50%;
}
.banner-btn {
  margin-top: 40px;
  width: 166px;
  height: 44px;
  background: url("../assets/img/detection/phone.png");
  background-size: 100% 100%;
  color: #7abd27;
  display: flex;
  padding-left: 65px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: transform 0.3s;
}
.banner-btn:hover {
  transform: scale(1.1);
}
.banner-btn:active {
  transform: scale(1);
}
.title-bottom {
  margin-top: 20px;
  width: 80px;
  height: 3px;
  background-color: #7abd27;
}
/* 检测服务 */
.detection-box {
  padding: 82px 146px;
  background-color: #efefef;
}
.detection-action {
  width: 100%;
  padding-left: 120px;
  height: 623px;
  background-color: #fff;
  border-radius: 20px;
  background: url("../assets/img/pilotplant/banner3.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
}
.detection-action-title {
  font-size: 40px;
  font-weight: 600;
}
.detection-action-text {
  color: #5d5d5d;
  margin: 20px 0 40px;
}
.detection-action-li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.detection-action-li span {
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #7abd27;
  transform: rotate(45deg);
  margin-right: 15px;
}
/* 测试能力 */
.test-ability {
  width: 100%;
  background-size: auto 120% !important;
  background-position: 0 center !important;
}
.test-ability-receptacle {
  width: 100%;
  padding: 120px 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #ffffff82 0%, #fff 50%, #fff 100%);
}
.test-ability-title {
  font-size: 40px;
  font-weight: 600;
}
.test-ability-action {
  margin-top: 50px;
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.test-ability-action-left {
  height: 350px;
}
.test-ability-action-left-title > span {
  font-size: 32px;
  font-weight: 600;
}
.action-title-line {
  margin-top: 20px;
  margin-bottom: 39px;
  width: 100%;
  height: 4px;
  background-color: #eee;
}
.action-title-line-action {
  width: 80px;
  height: 100%;
  background-color: #7abd27;
}
.test-ability-action-left-text {
  font-size: 16px;
  margin-bottom: 15px;
  color: #4f4f4d;
}
.test-ability-action-right {
  width: 1104px;
  height: 100%;
}
.swiper-handle {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.swiper-handle-left {
  width: calc(100% - 182px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swiper-paging {
  font-size: 22px;
  color: #999;
  user-select: none;
  cursor: pointer;
  transition: color 0.5s;
  font-weight: 600;
}
.swiper-paging-select {
  color: #000;
}
.swiper-handle-right {
  width: 103px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swiper-handle-prev,
.swiper-handle-next {
  width: 41px;
  height: 41px;
  border: 3px solid #99999994;
  border-radius: 50%;
  font-size: 20px;
  color: #7abd27;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.swiper-handle-prev:hover,
.swiper-handle-next:hover {
  transform: scale(1.02);
}
.swiper-handle-prev:active,
.swiper-handle-next:active {
  transform: scale(0.99);
}
.swiper-action {
  width: 100%;
  height: 350px;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swiper-slide-item {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.swiper-slide-item img {
  object-fit: cover;
}
.swiper-pagination {
  display: none;
}
.dialog-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000006a;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-action {
  width: 490px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.dialog-action-title {
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #daded7;
  font-size: 20px;
  padding: 0 20px;
}
.dialog-action-title i {
  cursor: pointer;
}
.dialog-action-module {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #666;
  padding-bottom: 50px;
}
.dialog-action-module i,
.dialog-action-module span {
  color: #7abd27;
  margin: 0 5px;
}
.dialog-action-module i {
  font-size: 30px;
}
</style>
