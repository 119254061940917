<template>
  <div class="page">
    <div id="section1">
      <img src="./assets/img/banner.png" />
      <div class="banner-msg">
        <div class="banner-msg-item" v-for="(item,index) in bannerMsg" :key="index">
          <div class="banner-msg-item-top">
            <span>
              <countTo :startVal="0" :endVal="item.num" :duration="2000" />
              {{index==1?'+':''}}
            </span>
            {{item.unit}}
          </div>
          <div class="banner-msg-item-botton">{{item.text}}</div>
        </div>
      </div>
    </div>

    <div id="section2">
      <span>中心简介</span>
      <div class="title-bottom"></div>
      <div
        class="intro"
      >国家虚拟现实创新中心（青岛）的运营单位是青岛虚拟现实研究院。公司总注册资金1.35亿元，股东涵盖歌尔、利亚德、中科创达等虚拟现实头部企业，覆盖零组件、整机、核心工具、内容应用、投资等虚拟现实全产业链条。创新中心致力于整合中心、高校、科研院所等各类创新资源，聚焦虚拟现实领域关键共性技术的研究突破，推动产业孵化和人才培养，推进全产业链创新、生态链构建、产业链协同发展，打造全球虚拟现实产业高地。</div>
      <div class="two-module">
        <div class="two-module-item-box">
          <img src="./assets/img/bg2-1.png" alt />
          <div class="two-module-item two-module-bg2">
            <span>中心愿景</span>
            <span>成为全球引领的虚拟现实创新生态发展驱动引擎</span>
          </div>
        </div>
        <div class="two-module-item-box">
          <img src="./assets/img/bg2-1.png" alt />
          <div class="two-module-item two-module-bg2">
            <span>中心使命</span>
            <span>补创新链、强制造链、稳固生态、赋能数字经济</span>
          </div>
        </div>
      </div>
    </div>
    <div id="section3">
      <span>六大平台</span>
      <div class="title-bottom"></div>
      <div class="six-module">
        <div
          class="six-module-item"
          v-for="(item,index) in sixList"
          :key="index"
          @click="jumpSix(index)"
        >
          <img :src="item.img2" alt />
          <div class="six-module-item-title">{{item.title}}</div>
          <div class="six-module-item-text">
            <span v-for="(t,i) in item.text" :key="i">{{t}}</span>
          </div>
        </div>
      </div>
    </div>
    <div id="section4">
      <span>组织架构</span>
      <div class="title-bottom"></div>
      <div class="frame-work">
        <frameWork />
      </div>
    </div>
    <div id="section5">
      <span>发展历程</span>
      <div class="title-bottom"></div>
      <div class="develop">
        <div class="develop-action">
          <div class="develop-left">
            <div class="develop-left-item">
              <horseList :list="developImg" />
            </div>
            <div class="develop-left-item">
              <horseListFlip :list="developImg2" />
            </div>
          </div>
          <div class="develop-right">
            <div class="develop-right-action">
              <div class="develop-right-item" v-for="(item,index) in yearMsg" :key="index">
                <div class="develop-right-item-year">{{item.year}}</div>
                <div class="develop-right-item-module">
                  <div class="develop-right-item-title">
                    <div v-html="item.title"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";
import horseList from "@/components/horse-list";
import horseListFlip from "@/components/horse-list-flip";
import frameWork from "./components/frameWork.vue";
export default {
  components: { countTo, horseList, horseListFlip, frameWork },
  data() {
    return {
      bannerMsg: [
        {
          num: 5,
          unit: "名",
          text: "专家院士"
        },
        {
          num: 300,
          unit: "",
          text: "联盟合作单位"
        },
        {
          num: 19,
          unit: "名",
          text: "股东单位"
        }
      ],
      sixList: [
        {
          img1: require("@/views/index-page/assets/img/pingtai/gongxing.png"),
          img2: require("@/views/index-page/assets/img/pingtai/gongxing1.png"),
          title: "共性技术研发平台",
          text: ["终端技术实验室", "图形运算实验室", "人机交互实验室"]
        },
        {
          img1: require("@/views/index-page/assets/img/pingtai/jianyan.png"),
          img2: require("@/views/index-page/assets/img/pingtai/jianyan1.png"),
          title: "检测平台",
          text: ["多功能移动测试平台"]
        },
        {
          img1: require("@/views/index-page/assets/img/pingtai/zhongshi.png"),
          img2: require("@/views/index-page/assets/img/pingtai/zhongshi1.png"),
          title: "中试平台",
          text: ["虚拟现实整机中试平台", "表面浮雕波导中试平台"]
        },
        {
          img1: require("@/views/index-page/assets/img/pingtai/jishu.png"),
          img2: require("@/views/index-page/assets/img/pingtai/jishu1.png"),
          title: "技术服务平台",
          text: [
            "设备共享",
            "技术开发",
            "技术咨询",
            "检测服务",
            "知识产权",
            "公共数据库"
          ]
        },
        {
          img1: require("@/views/index-page/assets/img/pingtai/rencai.png"),
          img2: require("@/views/index-page/assets/img/pingtai/rencai1.png"),
          title: "人才培养平台",
          text: ["人才培养", "人才培训", "人才交流合作"]
        },
        {
          img1: require("@/views/index-page/assets/img/pingtai/guoji.png"),
          img2: require("@/views/index-page/assets/img/pingtai/guoji1.png"),
          title: "国际交流与合作平台",
          text: ["国际交流活动", "跨国项目合作"]
        }
      ],
      sixIndex: 0,
      developImg: [
        require("./assets/img/animation/1.png"),
        require("./assets/img/animation/2.png"),
        require("./assets/img/animation/3.png"),
        require("./assets/img/animation/4.jpg"),
        require("./assets/img/animation/5.jpg"),
        require("./assets/img/animation/6.png")
      ],
      developImg2: [
        require("./assets/img/animation/7.png"),
        require("./assets/img/animation/8.png"),
        require("./assets/img/animation/9.png"),
        require("./assets/img/animation/10.png"),
        require("./assets/img/animation/11.png"),
        require("./assets/img/animation/12.png")
      ],
      yearMsg: [
        {
          year: "2024",
          title:
            "国创中心（青岛）检测平台投入运营<br/>国创中心（青岛）中试平台投入运营"
        },
        {
          year: "2023",
          title:
            "国创中心（青岛）新办公大楼正式启用<br/> 获批虚拟现实文化和旅游部技术创新中心<br/> 牵头承担工信部产业技术基础公共服务平台项目"
        },
        {
          year: "2022",
          title:
            "获批国家虚拟现实创新中心（青岛）<br/> 获批山东省“虚拟现实”科技示范工程"
        },
        {
          year: "2019",
          title:
            "青岛虚拟现实研究院有限公司成立<br/>获批山东省虚拟现实制造业创新中心"
        }
      ]
    };
  },

  created() {},
  mounted() {},

  methods: {
    jumpSix(index) {
      this.$router.push({ path: "/serverPage", query: { menuIndex: index } });
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style scoped lang="css" src="./assets/css/index.css" />
