import Vue from 'vue'
import VueRouter from 'vue-router'

import pageBox from '@/views/page-box/page-box.vue'
import indexPage from '@/views/index-page/index-page.vue'
import aboutPage from '@/views/about-page/about-page.vue'
import ecologyPage from '@/views/ecology-page/ecology-page.vue'
import serverPage from '@/views/server-page/server-page.vue'
import achievementPage from '@/views/achievement-page/achievement-page.vue'
import newPage from '@/views/new-page/new-page.vue'
import newDetailPage from '@/views/new-detail-page/new-detail-page.vue'
import contactPage from '@/views/contact-page/contact-page.vue'

Vue.use(VueRouter)
//push 
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: "/pageBox"
  },
  {
    path: '/pageBox',
    name: 'pageBox',
    component: pageBox,
    redirect: "/indexPage",
    children: [
      {
        path: '/indexPage',
        name: 'indexPage',
        component: indexPage,
        meta: { title: '首页' },
      },
      {
        path: '/aboutPage',
        name: 'aboutPage',
        component: aboutPage,
        meta: { title: '关于我们' },
      },
      {
        path: '/ecologyPage',
        name: 'ecologyPage',
        component: ecologyPage,
        meta: { title: '创新生态' },
      },
      {
        path: '/serverPage',
        name: 'serverPage',
        component: serverPage,
        meta: { title: '创新服务' },
      },
      {
        path: '/achievementPage',
        name: 'achievementPage',
        component: achievementPage,
        meta: { title: '创新成果' },
      },
      {
        path: '/newPage',
        name: 'newPage',
        component: newPage,
        meta: { title: '新闻动态' },
      },
      {
        path: '/newDetailPage',
        name: 'newDetailPage',
        component: newDetailPage,
        meta: { title: '新闻动态详情' },
      },

      {
        path: '/contactPage',
        name: 'contactPage',
        component: contactPage,
        meta: { title: '联系我们' },
      },

    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  // if (to.meta.title) {
  //   document.title = '心理协会 - ' + to.meta.title
  // }
  next()
})
export default router
