<template>
  <div class="page">
    <div class="side-nav">
      <a
        v-for="(section, index) in sections"
        :key="index"
        :href="'#' + section.id"
        :class="{ active: activeSection === section.id }"
        @click.prevent="scrollToSection(section.id)"
      ></a>
    </div>

    <div id="section1">
      <video muted="muted" autoplay="autoplay" preload="auto">
        <source src="./assets/img/logo2.mp4" type="video/mp4" />
      </video>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in bannerList" :key="index" class="swiper-slide">
            <img :src="item" />
            <div class="kouhao-yu" v-if="index==1">
              <span>广汇聚 强驱动</span>
              <p>
                <span>打造</span>虚拟现实产业高地
              </p>
            </div>
            <div class="xuanchuan-yu" v-if="index==2">
              <span>虚拟现实应用开发</span>
              <span>专业技术人才（高级）培训班</span>
              <div @click="jumpSearch()">点击查询 &gt;</div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div class="section-pagination"></div>
    </div>
    <!-- 圆弧特效暂时注释掉 -->
    <!-- <div id="section-substitute"></div>
    <div id="section-substitute2"></div>
    <circular-scaling />-->

    <!-- 第二个板块注释掉 -->
    <!-- <div id="section2">
      <video id="video" muted="muted" autoplay="autoplay" preload="auto">
        <source src="./assets/img/logo2.mp4" type="video/mp4" />
      </video>
    </div>-->
    <div id="section3">
      <video muted="muted" autoplay="autoplay" preload="auto" loop="loop">
        <source src="./assets/img/dongtai.mp4" type="video/mp4" />
      </video>
      <tip-btn
        class="tip-btn"
        v-for="(item,index) in pointList"
        :key="index+'btn'"
        :rotation="item.rotation"
        :style="{
            left:item.x,
            top:item.y
          }"
        @click.native="getOldBoxColor(index)"
      />
      <div
        class="tip-dia"
        v-for="(item,index) in pointList"
        :key="index+'dia'"
        :ref="`tip${index}`"
        :class="item.dia_class"
        :style="{
            left:item.dia_x,
            top:item.dia_y
          }"
      >
        <span>{{item.title}}</span>
        <span @click="jumpMore(index)">了解更多</span>
      </div>
    </div>
    <div id="section4">
      <div class="gamma">
        <div class="gamma-right" :class="aroundAnim?'gamma-right-start':''">
          <img src="./assets/img/gamma-right.png" v-if="pingtaiIndex==null" />
          <img src="@/views/server-page/assets/img/exploit/banner.png" v-else-if="pingtaiIndex==0" />
          <img
            src="@/views/server-page/assets/img/detection/banner.png"
            v-else-if="pingtaiIndex==1"
          />
          <img
            src="@/views/server-page/assets/img/pilotplant/banner.png"
            v-else-if="pingtaiIndex==2"
          />
          <img src="@/views/server-page/assets/img/server/banner.png" v-else-if="pingtaiIndex==3" />
          <img
            src="@/views/server-page/assets/img/cultivate/banner.png"
            v-else-if="pingtaiIndex==4"
          />
          <img src="@/views/server-page/assets/img/teamwork/banner.png" v-else-if="pingtaiIndex==5" />
        </div>
        <div class="gamma-left" :class="aroundAnim?'gamma-left-start':''">
          <div class="gamma-left-text">重点建设六大平台，推动虚拟现实产业补链、强链、稳链、固链发展，实现创新资源由内部封闭向开放共享平台转变</div>
          <div class="gamma-left-btn">
            <div
              class="gamma-left-btn-item"
              v-for="(item,index) in pingtaiList"
              :key="index"
              @click="jumpSix(index)"
              @mouseenter="sixMouseEnter(index)"
            >
              <div class="img-box">
                <img class="img1" :src="item.img1" />
                <img class="img2" :src="item.img2" />
              </div>
              <span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="huakuai-box">
        <div class="huakuai" :style="{right:huakuai_position+'px'}"></div>
      </div>
    </div>
    <div id="section5">
      <span>荣誉资质</span>
      <div class="title-bottom"></div>
      <p>
        承担国家级项目
        <span>5</span>
        项，省部级项目
        <span>5</span>
        项，参与制订国际/国家/团体标准
        <span>10</span>
        项，获虚拟现实领域知名奖项10余项。
      </p>
      <div class="p-box">
        <p class="p-box1">《 获批工信部2023年产业技术基础公共服务平台建设 》</p>
        <p class="p-box2">《 获批文旅部技术创新中心建设 》</p>
        <p class="p-box3">《 获批山东省“虚拟现实”科技示范工程 》</p>
        <p class="p-box4">《 获批国家虚拟现实制造业创新中心创新能力建设 》</p>
        <p class="p-box5">&nbsp;&nbsp;......</p>
        <p class="p-box6">&nbsp;&nbsp;2个项目入选山东省虚拟现实先锋应用案例</p>
        <p class="p-box7">&nbsp;&nbsp;中国产学研合作创新奖</p>
        <p class="p-box8">&nbsp;&nbsp;......</p>
      </div>
    </div>
    <div id="section6">
      <span>新闻动态</span>
      <div class="title-bottom"></div>
      <div class="new-box">
        <div class="new-menu">
          <div
            class="new-menu-item"
            v-for="(item,index) in newMenu"
            :key="index"
            :class="index==newIndex?'new-menu-item-select':''"
            @click="getXinwenFun(index)"
          >{{item.name}}</div>
        </div>
        <newsSwiper :swiperList="newsList" :key="newsList.length" />
      </div>
    </div>
    <div id="section7">
      <span>股东单位</span>
      <div class="title-bottom"></div>
      <p class="qiu-introduce">股东涵盖歌尔、利亚德、中科创达、中视典等19家虚拟现实头部企业，覆盖零组件、整机、核心工具、内容应用、投资等全产业链条。</p>
      <div class="qiu-list">
        <div
          class="qiu-item"
          v-for="(item,index) in gudongList"
          :key="index"
          :style="item.style"
          :class="item.size"
        >
          <img :src="item.imgSrc" :style="{width:item.size=='qiu-item-size1'?'100%':''}" />
        </div>
      </div>
      <div class="qiu-list-apostrophe">......</div>
    </div>
    <div id="section8">
      <span>联系我们</span>
      <div class="title-bottom"></div>
      <div class="contact-us">
        <div class="contact-us-map">
          <el-amap
            ref="map"
            :vid="'amapDemo'"
            :center="[info.longitude, info.latitude]"
            :zoom="12"
            class="amap-demo"
            style="height: 100%;width: 100%"
          >
            <el-amap-marker :position="[info.longitude, info.latitude]"></el-amap-marker>
          </el-amap>
        </div>
        <div class="contact-us-form">
          <div class="contact-us-form-title">业务咨询</div>
          <div class="contact-us-form-item">
            <input placeholder="称呼" v-model="consultFrom.name" />
          </div>
          <div class="contact-us-form-item">
            <input placeholder="电话" v-model="consultFrom.mobile" />
          </div>
          <div class="contact-us-form-item">
            <input placeholder="邮箱" v-model="consultFrom.email" />
          </div>
          <div class="contact-us-form-item">
            <input placeholder="业务范畴" v-model="consultFrom.business" />
          </div>
          <div class="contact-us-form-item contact-us-form-item2">
            <textarea placeholder="详情描述" v-model="consultFrom.content" />
          </div>
          <div class="contact-us-form-btn" @click="consultSubmit()">提交</div>
          <!-- <div class="contact-us-form-icon">
            <img src="./assets/img/dianhua.png" />
            <img src="./assets/img/youxiang.png" />
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 圆弧特效暂时注释掉
// import circularScaling from "@/components/circular-scaling/index";
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import TipBtn from "@/components/TipBtn/index";
import { mapState } from "vuex";
import newsSwiper from "./components/newsSwiper.vue";
import { getNews } from "@/api/tuwen";
import { submit_form } from "@/api/submit";
export default {
  components: {
    // circularScaling,
    TipBtn,
    newsSwiper
  },
  data() {
    return {
      // 板块指示点模块
      sections: [
        { id: "section1" },
        // 第二个板块注释掉
        // { id: "section2" },
        { id: "section3" },
        { id: "section4" },
        { id: "section5" },
        { id: "section6" },
        { id: "section7" },
        { id: "section8" }
      ],
      activeSection: "",
      // 动态城市弹窗标点
      pointList: [
        {
          x: "50%",
          y: "38%",
          dia_x: "42.5%",
          dia_y: "21%",
          title: "国家虚拟现实创新中心（青岛）",
          dia_class: "tip-dia1",
          rotation: 0
        },
        {
          x: "60%",
          y: "60%",
          dia_x: "54.5%",
          dia_y: "45%",
          title: "中试平台",
          dia_class: "tip-dia2",
          rotation: 0
        },
        {
          x: "70%",
          y: "70%",
          dia_x: "73%",
          dia_y: "65%",
          title: "检测平台",
          dia_class: "tip-dia3",
          rotation: 0
        }
      ],
      // 平台列表
      pingtaiList: [
        {
          img1: require("./assets/img/pingtai/gongxing.png"),
          img2: require("./assets/img/pingtai/gongxing1.png"),
          title: "共性技术研发平台"
        },
        {
          img1: require("./assets/img/pingtai/jianyan.png"),
          img2: require("./assets/img/pingtai/jianyan1.png"),
          title: "检测平台"
        },
        {
          img1: require("./assets/img/pingtai/zhongshi.png"),
          img2: require("./assets/img/pingtai/zhongshi1.png"),
          title: "中试平台"
        },
        {
          img1: require("./assets/img/pingtai/jishu.png"),
          img2: require("./assets/img/pingtai/jishu1.png"),
          title: "技术服务平台"
        },
        {
          img1: require("./assets/img/pingtai/rencai.png"),
          img2: require("./assets/img/pingtai/rencai1.png"),
          title: "人才培养平台"
        },
        {
          img1: require("./assets/img/pingtai/guoji.png"),
          img2: require("./assets/img/pingtai/guoji1.png"),
          title: "国际交流与合作平台"
        }
      ],
      pingtaiIndex: null,
      huakuai_position: 0,
      aroundAnim: false,
      // 股东单位数组
      gudongList: [
        {
          style: "left: 5%;top:5%;",
          size: "qiu-item-size3",
          imgSrc: require("./assets/img/gudong/gu_03.jpg")
        },
        {
          style: "right: 5%;top:5%;",
          size: "qiu-item-size3",
          imgSrc: require("./assets/img/gudong/gu_06.png")
        },
        {
          style: "left: 22%;top:10%;",
          size: "qiu-item-size3",
          imgSrc: require("./assets/img/gudong/gu_45.png")
        },
        {
          style: "right: 22%;top:10%;",
          size: "qiu-item-size3",
          imgSrc: require("./assets/img/gudong/gu_11.jpg")
        },
        {
          style: "left: 30%;top:40%;",
          size: "qiu-item-size2",
          imgSrc: require("./assets/img/gudong/gu_17.jpg")
        },
        {
          style: "right: 30%;top:40%;",
          size: "qiu-item-size2",
          imgSrc: require("./assets/img/gudong/gu_20.jpg")
        },
        {
          style: "left: 0%;top:45%;",
          size: "qiu-item-size4",
          imgSrc: require("./assets/img/gudong/gu_26.jpg")
        },
        {
          style: "right: 0%;top:45%;",
          size: "qiu-item-size4",
          imgSrc: require("./assets/img/gudong/gu_55.jpg")
        },
        {
          style: "left: 14%;top:30%;",
          size: "qiu-item-size4",
          imgSrc: require("./assets/img/gudong/gu_29.jpg")
        },
        {
          style: "right: 14%;top:30%;",
          size: "qiu-item-size4",
          imgSrc: require("./assets/img/gudong/gu_35.jpg")
        },
        {
          style: "left: 34%;top:5%;",
          size: "qiu-item-size2",
          imgSrc: require("./assets/img/gudong/gu_52.jpg")
        },
        {
          style: "right: 34%;top:5%;",
          size: "qiu-item-size2",
          imgSrc: require("./assets/img/gudong/gu_47.jpg")
        },
        {
          style: "top:50%;left: 50%;transform: translate(-50%,-50%);",
          size: "qiu-item-size1",
          imgSrc: require("./assets/img/gudong/guochuangy.png")
        },
        {
          style: "left: 16%;bottom:17%;",
          size: "qiu-item-size2",
          imgSrc: require("./assets/img/gudong/gu_60.jpg")
        },
        {
          style: "right: 16%;bottom:17%;",
          size: "qiu-item-size2",
          imgSrc: require("./assets/img/gudong/guochuang.png")
        },
        {
          style: "left: 32%;bottom:0%;",
          size: "qiu-item-size3",
          imgSrc: require("./assets/img/gudong/gu_68.jpg")
        },
        {
          style: "right: 32%;bottom:0%;",
          size: "qiu-item-size3",
          imgSrc: require("./assets/img/gudong/lingmeixin.jpg")
        }
      ],
      //新闻资讯菜单
      newMenu: [
        { name: "中心动态" },
        { name: "领导关怀" },
        { name: "通知公告" }
      ],
      newIndex: 0,
      // swiper列表
      bannerList: [
        require("./assets/img/banner-list/1.png"),
        require("./assets/img/banner-list/2.png"),
        require("./assets/img/banner-list/3.png")
      ],
      newsList: [],
      //地图
      info: {
        latitude: "36.137405",
        longitude: "120.46282"
      },
      consultFrom: {
        name: "",
        mobile: "",
        email: "",
        business: "",
        content: ""
      }
    };
  },

  mounted() {
    setTimeout(() => {
      this.getBanner();
      // 视频8秒
    }, 7000);
    this.onScroll(this.clientTop);
    this.getXinwenFun(0);
  },
  // 获取vuex中存储的页面距离顶部距离
  computed: {
    ...mapState(["clientTop"])
  },
  // 监听页面距离顶部距离
  watch: {
    clientTop(newVal) {
      // 锚点功能
      this.onScroll(newVal);
      // 六大平台下面的滑块
      let element = document.querySelector(".huakuai-box");
      let rect = element.getBoundingClientRect();
      let ih = rect.bottom - window.innerHeight;
      this.huakuai_position = -ih;
      // 六大平台模块进入窗口后执行动画
      let box = document.getElementById("section4");
      let show =
        box.getBoundingClientRect().bottom -
        box.clientHeight -
        window.innerHeight;
      if (show < 0) {
        this.aroundAnim = true;
      }
      // 荣誉资质
      let ryzz = document.querySelector(".p-box");
      let ryzzmsg =
        ryzz.getBoundingClientRect().bottom -
        ryzz.clientHeight -
        window.innerHeight;
      if (ryzzmsg < 0) {
        this.pBoxFun();
      }
    }
  },
  methods: {
    consultSubmit() {
      if (this.consultFrom.name == "") {
        return this.$message({
          message: "请输入称呼",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.consultFrom.mobile.length != 11) {
        return this.$message({
          message: "请输入正确电话号",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.consultFrom.email == "") {
        return this.$message({
          message: "请输入邮箱",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.consultFrom.business == "") {
        return this.$message({
          message: "请输入业务范畴",
          type: "error",
          customClass: "messageIndex"
        });
      }
      if (this.consultFrom.content == "") {
        return this.$message({
          message: "请输入详情描述",
          type: "error",
          customClass: "messageIndex"
        });
      }
      submit_form(this.consultFrom).then(res => {
        this.$message({
          message: res.msg,
          type: "sucess",
          customClass: "messageIndex"
        });
        for (let key in this.consultFrom) {
          this.consultFrom[key] = "";
        }
      });
    },
    pBoxFun() {
      let start = 0.4;
      let end;
      for (let i = 1; i <= 8; i++) {
        end = start + 0.4;
        let yuansu = document.querySelector(".p-box" + i);
        yuansu.style.animation = "ani " + start + "s " + end + "s both";
        start = end;
      }
    },
    getXinwenFun(index) {
      this.newIndex = index;
      getNews({ page: 1, limit: 10, cid: this.newIndex + 1 }).then(res => {
        this.newsList = 0;
        this.newsList = res.data.info;
      });
    },
    jumpSix(index) {
      this.$router.push({ path: "/serverPage", query: { menuIndex: index } });
      window.scrollTo(0, 0);
    },
    sixMouseEnter(index) {
      this.pingtaiIndex = index;
    },
    jumpMore(index) {
      if (index === 0) {
        // 关于我们
        this.$router.push({ path: "/aboutPage" });
        window.scrollTo(0, 0);
      } else {
        this.jumpSix(index == 1 ? 2 : 1);
      }
    },
    getBanner() {
      // bannner模块
      new Swiper("#section1 .swiper-container", {
        loop: true,
        autoplay: {
          delay: 4000
        },
        paginationClickable: true,
        slidesPerView: 1, // 1组1个
        spaceBetween: "0%", // 间隔
        navigation: {
          nextEl: "#section1 .swiper-button-next",
          prevEl: "#section1 .swiper-button-prev"
        },
        observer: true,
        observeParents: true,
        pagination: {
          el: "#section1 .section-pagination"
        }
      });
    },
    scrollToSection(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    // replayVideo() {
    //   const video = document.getElementById("video");
    //   video.load();
    // },
    getOldBoxColor(index) {
      const type = this.$refs[`tip${index}`][0].style.transform == "scale(1)";
      this.actionPoint([index], !type);
      if (!type) {
        this.actionPoint(
          index == 0 ? [1, 2] : index == 1 ? [0, 2] : [0, 1],
          false
        );
      }
    },
    actionPoint(arr, status) {
      for (let i = 0; i < arr.length; i++) {
        this.pointList[arr[i]].rotation = status ? 135 : 0;
        this.$refs[`tip${arr[i]}`][0].style.transform = `scale(${
          status ? 1 : 0
        })`;
      }
    },
    jumpSearch() {
      window.open("http://certificate.qdvri.com/");
    },
    onScroll() {
      // 检查当前页面滚动位置，激活对应的标点
      this.sections.forEach(section => {
        const element = document.getElementById(section.id);
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          if (this.activeSection != section.id) {
            // 第二个板块注释掉
            // if (this.activeSection == "section1" && section.id == "section2") {
            //   this.replayVideo();
            // }
            this.activeSection = section.id;
          }
        }
      });
      // 圆弧特效暂时注释掉
      // 0-100vh是第一模块，100-200vh是第二模块
      // const scrollTop = this.clientTop;
      // const viewportHeight = window.innerHeight;
      // const element1 = document.getElementById("section1");
      // const elementT1 = document.getElementById("section-substitute");
      // const element2 = document.getElementById("section2");
      // const elementT2 = document.getElementById("section-substitute2");
      // if (scrollTop < viewportHeight) {
      //   console.log("现在处于第一模块位置");
      //   elementT1.style.height = "100vh";
      //   elementT2.style.height = "0vh";
      //   element2.style.position = "static";
      //   element1.style.position = "fixed";
      // } else if (
      //   scrollTop > viewportHeight &&
      //   scrollTop <= viewportHeight * 3
      // ) {
      //   console.log("现在处于第二模块位置");
      //   element1.style.position = "static";
      //   element2.style.position = "fixed";
      //   element2.style.top = "0";
      //   elementT2.style.height = "100vh";
      // } else {
      //   console.log("现在处于其他模块");
      //   element2.style.position = "static";
      //   elementT2.style.height = "100vh";
      // }
    }
  }
};
</script>
<style>
@keyframes ani {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
<style scoped lang="css" src="./assets/css/index.css" />
