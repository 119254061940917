import { render, staticRenderFns } from "./ServerMatter2.vue?vue&type=template&id=2580b66e&scoped=true"
import script from "./ServerMatter2.vue?vue&type=script&lang=js"
export * from "./ServerMatter2.vue?vue&type=script&lang=js"
import style0 from "./ServerMatter2.vue?vue&type=style&index=0&id=2580b66e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2580b66e",
  null
  
)

export default component.exports