<template>
  <div>
    <div class="master-branch">
      <img src="./img/1.png" alt />
      <div class="master-branch-btn btn1" @click="show1=!show1">
        <img v-if="show1" src="./img/minus.png" alt />
        <img v-else src="./img/add.png" alt />
      </div>
      <div class="master-branch-btn btn2" @click="show2=!show2">
        <img v-if="show2" src="./img/minus.png" alt />
        <img v-else src="./img/add.png" alt />
      </div>
      <div class="master-branch-btn btn3" @click="show3=!show3">
        <img v-if="show3" src="./img/minus.png" alt />
        <img v-else src="./img/add.png" alt />
      </div>
    </div>
    <div class="sub-branch">
      <div class="sub-branch-item sub-branch1" :class="show1?'sub-branch-item-select':''">
        <img src="./img/2.png" alt />
      </div>
      <div class="sub-branch-item sub-branch2" :class="show2?'sub-branch-item-select':''">
        <img src="./img/3.png" alt />
      </div>
      <div class="sub-branch-item sub-branch3" :class="show3?'sub-branch-item-select':''">
        <img src="./img/4.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "frameWork",
  data() {
    return {
      show1: false,
      show2: false,
      show3: false
    };
  },
  mounted() {},
  methods: {}
};
</script>
<style scoped lang="scss">
.master-branch {
  position: relative;
  width: 100%;
}
.master-branch img {
  width: 100%;
  height: auto;
}
.master-branch-btn {
  position: absolute;
  bottom: 0;
  transform: translate(0, 50%);
  width: 21px;
  height: auto;
  cursor: pointer;
}
.btn1 {
  left: 22.25%;
}
.btn2 {
  left: 59.8%;
}
.btn3 {
  left: 89.4%;
}
.sub-branch {
  width: 100%;
  display: flex;
}

.sub-branch-item {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}
.sub-branch-item-select {
  height: 360px;
}

.sub-branch-item img {
  width: 100%;
  height: auto;
}

.sub-branch1 {
  width: 44%;
}

.sub-branch2 {
  width: 35%;
}

.sub-branch3 {
  width: 21%;
}
</style>
    