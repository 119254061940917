<template>
  <div class="page-box">
    <div class="banner">
      <img src="./assets/img/banner.png" alt />
    </div>
    <div class="new-list">
      <div class="new-list-detail">
        <div class="new-list-title">{{info.title}}</div>
        <div v-html="info.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsDetail } from "@/api/tuwen";
export default {
  data() {
    return {
      info: "11111"
    };
  },

  created() {
    this.getFun();
  },
  mounted() {},

  methods: {
    getFun() {
      getNewsDetail(this.$route.query).then(res => {
        this.info = res.data;
      });
    }
  }
};
</script>

<style scoped lang="css" src="./assets/css/index.css" />
