<template>
  <div>
    <div class="banner-box">
      <img class="banner" src="../assets/img/teamwork/banner.png" alt />
      <div class="banner-text">
        <div class="banner-text1">
          开展国际合作，畅通虚拟现实全球产业链、供应链，
          <br />推动国内国际双循环相互促进
        </div>
        <div class="banner-text2">
          Carry out international cooperation, smooth the global industry chain and supply chain of virtual reality,
          <br />Promote mutual promotion of domestic and international dual circulation
        </div>
      </div>
    </div>
    <!-- 交流活动 -->
    <div class="solve-box">
      <div class="solve-text">
        <div class="solve-text1">交流活动</div>
        <div class="title-bottom"></div>
      </div>
      <div class="solve-action">
        <div class="scheme-box">
          <div class="scheme-prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="scheme-next">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in projectList" :key="index">
                <img :src="item.img" alt />
                <div class="swiper-slide-action">
                  <span class="text1">{{item.title}}</span>
                  <span class="text2">{{item.text}}</span>
                  <div class="swiper-slide-action-msg">
                    <!-- <span>{{item.time}}</span> -->
                    <!-- <span>查看详情&gt;</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      projectList: [
        {
          img: require("../assets/img/teamwork/project/1.png"),
          title: "国际虚拟现实创新大会",
          text: `由山东省工业和信息化厅、青岛市人民政府共同主办的国际性虚拟现实盛会。国家虚拟现实创新中心(青岛) 至今已参与4届，举办主题论坛3场。`,
          time: "2024.11.10"
        },
        {
          img: require("../assets/img/teamwork/project/2.png"),
          title: "2024太阳岛企业家年会政企数字化服务创新论坛",
          text: `8月23日下午，2024太阳岛企业家年会政企数字化服务创新论坛在哈尔滨举办，国家虚拟现实创新中心(青岛)主任严小天正式发布与新华网客户端在虚拟现实技术领域的创新合作。`,
          time: "2024.11.10"
        },
        {
          img: require("../assets/img/teamwork/project/3.png"),
          title: "全球三对三足球冠军赛",
          text: `2024第一届“全球三对三足球冠军赛”由华体私募基金管理(北京)有限公司与国家虚拟现实创新中心（青岛）共同主办，集结全球24个国家顶尖战队，共同上演技巧与激情的顶尖较量。旨在通过赛事构建一个跨国文化交流与合作的平台，借助足球这一全球共通的语言，深化不同国家和地区间的相互理解和友谊。`,
          time: "2024.11.10"
        }
      ]
    };
  },
  mounted() {
    this.getSwiper();
  },

  methods: {
    getSwiper() {
      this.mySwiper = new Swiper(".swiper-container", {
        autoplay: false,
        loop: true,
        paginationClickable: true,
        slidesPerView: 3,
        spaceBetween: "2%",
        navigation: {
          nextEl: ".scheme-next",
          prevEl: ".scheme-prev"
        },
        observer: true,
        observeParents: true
      });
    }
  }
};
</script>

<style scoped lang="css">
.banner-box {
  position: relative;
  width: 100%;
  height: 985px;
  color: #000;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner img {
  object-fit: cover;
}
.banner-text {
  position: absolute;
  top: 200px;
  left: 164px;
}
.banner-text1 {
  font-size: 46px;
  font-weight: 600;
}

.banner-text2 {
  font-size: 26px;
  margin-top: 43px;
  opacity: 0.58;
}

.title-bottom {
  margin-top: 20px;
  width: 80px;
  height: 3px;
  background-color: #7abd27;
}
/* 交流活动 */
.solve-box {
  position: relative;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
  background-color: #f8f8f8;
}
.solve-text {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.solve-text1 {
  font-size: 32px;
  font-weight: 600;
}
.solve-text2 {
  font-size: 20px;
  margin-top: 37px;
  opacity: 0.58;
}
.solve-action {
  margin-top: 50px;
  width: 100%;
  padding: 0 200px;
}
.scheme-box {
  width: 100%;
  height: 607px;
  position: relative;
}
.scheme-prev,
.scheme-next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 52px;
  height: 52px;
  border: 3px solid #b8b8b8;
  border-radius: 50%;
  color: #000;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
}
.scheme-prev {
  left: -100px;
}
.scheme-next {
  right: -100px;
}
.scheme-prev:hover,
.scheme-next:hover {
  transform: translate(0, -50%) scale(1.1);
}
.scheme-prev:active,
.scheme-next:active {
  transform: translate(0, -50%) scale(1);
}
.swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 2px;
}
.swiper-slide {
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
}
.swiper-slide img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.swiper-slide-action {
  width: 100%;
  height: calc(100% - 280px);
  padding: 36px 36px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.swiper-slide-action .text1 {
  font-size: 20px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.swiper-slide-action .text2 {
  margin-top: 24px;
  font-size: 14px;
  color: #606060;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.swiper-slide-action-msg {
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swiper-slide-action-msg :nth-child(1) {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.swiper-slide-action-msg :nth-child(2) {
  font-size: 14px;
  color: #7abd27;
  cursor: pointer;
  user-select: none;
}
</style>