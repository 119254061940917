<template>
  <div style="position: absolute;">
    <div class="point">
      <img class="point-gif" src="./img/lianyi.gif" alt v-if="rotation==135" />
      <img
        class="point-png"
        :style="{ transform: `rotate(${rotation}deg)`, transition: 'transform 0.5s', transformOrigin: '50% 50%' }"
        src="./img/anniu.png"
        alt
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "TipBtn",
  props: {
    rotation: {
      required: true
    }
  }
};
</script>
<style scoped lang="css">
.point {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.point-gif {
  width: 100%;
  height: 100%;
  z-index: 0;
  transform: scale(3);
  opacity: 0.6;
}

.point-png {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: 0%;
  left: 0%;
}
</style>
  