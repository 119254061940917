var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"banner-box"},[_c('img',{staticClass:"banner",attrs:{"src":require("../assets/img/pilotplant/banner2.png"),"alt":""}}),_c('div',{staticClass:"banner-text"},[_c('div',{staticClass:"banner-text1"},[_vm._v("场地规模")]),_c('div',{staticClass:"title-bottom"}),_vm._m(1),_c('div',{staticClass:"banner-btn",on:{"click":function($event){_vm.fromShow=true}}},[_vm._v("中试申请")])])]),(_vm.fromShow)?_c('div',{staticClass:"dialog-box"},[_c('div',{staticClass:"dialog-action"},[_c('div',{staticClass:"dialog-action-title"},[_c('span',[_vm._v("中试申请")]),_c('i',{staticClass:"el-icon-close",on:{"click":function($event){_vm.fromShow=false}}})]),_vm._m(2)])]):_vm._e(),_vm._m(3),_c('div',{staticClass:"test-ability",style:({background:`url(${_vm.swiperPaging[_vm.pagingIndex].img}) no-repeat`})},[_c('div',{staticClass:"test-ability-receptacle"},[_c('div',{staticClass:"test-ability-title"},[_vm._v("测试能力")]),_c('div',{staticClass:"title-bottom"}),_c('div',{staticClass:"test-ability-action"},[_vm._m(4),_c('div',{staticClass:"test-ability-action-right"},[_c('div',{staticClass:"swiper-handle"},[_c('div',{staticClass:"swiper-handle-left"},_vm._l((_vm.swiperPaging),function(item,index){return _c('div',{key:index,staticClass:"swiper-paging",class:index==_vm.pagingIndex?'swiper-paging-select':'',on:{"click":function($event){return _vm.goSwiper(index)}}},[_vm._v(_vm._s(item.name))])}),0),_vm._m(5)]),_c('div',{staticClass:"swiper-action"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.swiperPaging),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-item"},[_c('img',{attrs:{"src":item.img,"alt":""}})])])}),0)]),_c('div',{staticClass:"swiper-pagination"})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-box"},[_c('img',{staticClass:"banner",attrs:{"src":require("../assets/img/pilotplant/banner.png"),"alt":""}}),_c('div',{staticClass:"banner-text"},[_c('div',{staticClass:"banner-text1"},[_vm._v(" 提升虚拟现实产品设计验证及迭代优化能力 "),_c('br'),_vm._v("畅通中小企业批量试制“断点”与“堵点” ")]),_c('div',{staticClass:"banner-text2"},[_vm._v(" Enhance the capability of virtual reality product design validation and iterative optimizationSmooth the \"breakpoints\" and \"bottlenecks\" of batch trial production for small and medium-sized enterprises ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-text2"},[_c('div',{staticClass:"banner-text2-highlight"},[_c('span'),_vm._v(" 虚拟现实整机中试线       "),_c('span'),_vm._v(" 表面浮雕光波导中试线 ")]),_vm._v("整体规模 "),_c('span',[_vm._v("2400")]),_vm._v(" ㎡，涵盖生产车间与技术测试区域。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dialog-action-module"},[_c('i',{staticClass:"el-icon-message"}),_vm._v(" 联系邮箱： "),_c('span',[_vm._v("margarita.wang@qdvri.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detection-box"},[_c('div',{staticClass:"detection-action"},[_c('div',{staticClass:"detection-action-title"},[_vm._v("生产能力")]),_c('div',{staticClass:"detection-action-text"},[_vm._v("搭建千级无尘车间，面向XR线体的完整生产线，年产可达15万台，可应对多类型产品生产")]),_c('div',{staticClass:"detection-action-li"},[_c('span'),_vm._v(" 千级无尘车间满足光学器件组装之需 ")]),_c('div',{staticClass:"detection-action-li"},[_c('span'),_vm._v(" 光机与波导双目自动AA,可同步达成A测，极力提升开发效率 ")]),_c('div',{staticClass:"detection-action-li"},[_c('span'),_vm._v(" 采用2D/3D高精度视觉引导五轴点胶设备，满足大曲面屏特异形状的点胶场景需求 ")]),_c('div',{staticClass:"detection-action-li"},[_c('span'),_vm._v(" 配置激光焊接机，等离子清洗机，可满足XR产品精密零组件的开发 ")]),_c('div',{staticClass:"detection-action-li"},[_c('span'),_vm._v(" MES系统，生产数据可追溯 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test-ability-action-left"},[_c('div',{staticClass:"test-ability-action-left-title"},[_c('span',[_vm._v("涵盖XR产品所有基础功能测试")]),_c('div',{staticClass:"action-title-line"},[_c('div',{staticClass:"action-title-line-action"})]),_c('div',{staticClass:"test-ability-action-left-text"},[_vm._v("蓝牙各信道性能测试")]),_c('div',{staticClass:"test-ability-action-left-text"},[_vm._v("WIFI性能测试（2.4G、5G、6G TXRX）")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-handle-right"},[_c('div',{staticClass:"swiper-handle-prev"},[_c('i',{staticClass:"el-icon-arrow-left"})]),_c('div',{staticClass:"swiper-handle-next"},[_c('i',{staticClass:"el-icon-arrow-right"})])])
}]

export { render, staticRenderFns }