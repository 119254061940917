<template>
  <div>
    <div class="banner-box">
      <img class="banner" src="../assets/img/cultivate/banner.png" alt />
      <div class="banner-text">
        <div class="banner-text1">
          <span>虚拟现实应用开发专业技术人才（高级）培训班</span>
        </div>
        <div
          class="banner-text2"
        >根据《虚拟现实与行业应用融合发展行动计划(2022-2026年)》的具体要求，为推进虚拟现实技术发展与产业人才培养，强新时代高技能人才队伍建设，深化产教融合、校企合作，国家虚拟现实创新中心(青岛)开设“2024 年虚拟现实应用开发专业技术人才(高级)培训班”</div>
        <div class="banner-btn" @click="jumpSearch()">点击查询&gt;</div>
      </div>
    </div>
    <!-- 培才动态 -->
    <div class="solve-box">
      <div class="solve-text">
        <div class="solve-text1">培才动态</div>
        <div class="title-bottom"></div>
      </div>
      <div class="solve-action">
        <div class="scheme-box">
          <div class="scheme-prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="scheme-next">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in projectList" :key="index">
                <img :src="item.img" alt />
                <div class="swiper-slide-action">
                  <span class="text1">{{item.title}}</span>
                  <span class="text2">{{item.text}}</span>
                  <div class="swiper-slide-action-msg">
                    <span>{{item.time}}</span>
                    <!-- <span>查看详情&gt;</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      projectList: [
        {
          img: require("../assets/img/cultivate/project/1.png"),
          title: "虚拟现实应用场景融合创新讲座",
          text: `讲座由国家虚拟现实创新中心（青岛）联合山东计算机学会共同策划。本次讲座邀请到陈清奎、梅结良、刘朋三位行业知名专家作报告演讲。创新中心相关负责人刘宁进行主持工作。本场讲座面向广大虚拟现实、数字媒体、计算机等相关专业的在校师生与从业人员，采用“线上+线下”相结合的培训方式，旨在积极推动虚拟现实领域应用人才的培养。`,
          time: "2024.11.10"
        },
        {
          img: require("../assets/img/cultivate/project/2.png"),
          title: "“歌尔杯”高校VR/AR挑战赛",
          text: `由歌尔股份有限公司发起并主办，国家虚拟现实创新中心(青岛)指导，山东省虚拟现实重点实验室和业内媒体VR陀螺支持，比赛内容覆盖VR/AR智能交互技术、整机创新设计、光学显示设计、行业应用以及数字创意内容&商业模式等细分领域。比赛吸引了来自清华大学、北京大学、浙江大学、西安交通大学、山东大学、剑桥大学、南洋理工大学等海内外千余名高校学生报名参加。至今已成功举办两届。`,
          time: "2024.11.10"
        },
        {
          img: require("../assets/img/cultivate/project/3.png"),
          title: "2024青少年虚拟现实内容创作大赛",
          text: `为推动虚拟现实教育普及与发展，提升青少年的创新意识、动手实践能力与科学综合素养，传承科技精神，在2024青少年虚拟现实内容创作大赛组委会的指导下，创新中心作为2024青少年虚拟现实内容创作大赛青岛赛区的承办单位在青岛举行本赛区现场评比环节。`,
          time: "2024.11.10"
        }
      ]
    };
  },
  mounted() {
    this.getSwiper();
  },

  methods: {
    getSwiper() {
      this.mySwiper = new Swiper(".swiper-container", {
        autoplay: false,
        loop: true,
        paginationClickable: true,
        slidesPerView: 3,
        spaceBetween: "2%",
        navigation: {
          nextEl: ".scheme-next",
          prevEl: ".scheme-prev"
        },
        observer: true,
        observeParents: true
      });
    },
    jumpSearch() {
      window.open("http://certificate.qdvri.com/");
    }
  }
};
</script>

<style scoped lang="css">
.banner-box {
  position: relative;
  width: 100%;
  height: 797px;
  color: #000;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner img {
  object-fit: cover;
}
.banner-text {
  position: absolute;
  top: 200px;
  left: 164px;
}
.banner-text1 {
  font-size: 46px;
  font-weight: 600;
}
.banner-text1 span {
  color: #7abd27;
}
.banner-text2 {
  font-size: 26px;
  width: 1046px;
  margin-top: 43px;
  opacity: 0.58;
}
.banner-btn {
  margin-top: 49px;
  width: 228px;
  height: 64px;
  background-color: #fff;
  border-radius: 6px;
  font-size: 26px;
  color: #7abd27;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.title-bottom {
  margin-top: 20px;
  width: 80px;
  height: 3px;
  background-color: #7abd27;
}
/* 培才动态 */
.solve-box {
  position: relative;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
  background-color: #f8f8f8;
}
.solve-text {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.solve-text1 {
  font-size: 32px;
  font-weight: 600;
}
.solve-text2 {
  font-size: 20px;
  margin-top: 37px;
  opacity: 0.58;
}
.solve-action {
  margin-top: 50px;
  width: 100%;
  padding: 0 200px;
}
.scheme-box {
  width: 100%;
  height: 607px;
  position: relative;
}
.scheme-prev,
.scheme-next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 52px;
  height: 52px;
  border: 3px solid #b8b8b8;
  border-radius: 50%;
  color: #000;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
}
.scheme-prev {
  left: -100px;
}
.scheme-next {
  right: -100px;
}
.scheme-prev:hover,
.scheme-next:hover {
  transform: translate(0, -50%) scale(1.1);
}
.scheme-prev:active,
.scheme-next:active {
  transform: translate(0, -50%) scale(1);
}
.swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 2px;
}
.swiper-slide {
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
}
.swiper-slide img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.swiper-slide-action {
  width: 100%;
  height: calc(100% - 280px);
  padding: 36px 36px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.swiper-slide-action .text1 {
  font-size: 20px;
  font-weight: 600;
}
.swiper-slide-action .text2 {
  margin-top: 24px;
  font-size: 14px;
  color: #606060;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.swiper-slide-action-msg {
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swiper-slide-action-msg :nth-child(1) {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.swiper-slide-action-msg :nth-child(2) {
  font-size: 14px;
  color: #7abd27;
  cursor: pointer;
  user-select: none;
}
</style>