var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"solve-box"},[_vm._m(1),_c('div',{staticClass:"solve-action"},[_c('div',{staticClass:"scheme-box"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.projectList),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"swiper-slide-action"},[_c('span',{staticClass:"text1"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"text2"},[_vm._v(_vm._s(item.text))])])])}),0)])])])]),_c('div',{staticClass:"solve-box"},[_vm._m(4),_c('div',{staticClass:"solve-action self-developed"},[_c('SelfDeveloped')],1)]),_c('div',{staticClass:"solve-box"},[_vm._m(5),_c('div',{staticClass:"solve-action equity-box"},_vm._l((_vm.equityList),function(item,index){return _c('div',{key:index,staticClass:"equity-item"},[_c('div',{staticClass:"equity-item-top"},[_vm._v(" "+_vm._s(item.num)+" "),_c('span',[_vm._v(_vm._s(item.unit))])]),_c('div',{staticClass:"equity-item-bottom"},[_vm._v(_vm._s(item.name))])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banenr-box"},[_c('img',{attrs:{"src":require("./assets/img/banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solve-text"},[_c('div',{staticClass:"solve-text1"},[_vm._v("产品方案")]),_c('div',{staticClass:"title-bottom"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scheme-prev"},[_c('i',{staticClass:"el-icon-arrow-left"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scheme-next"},[_c('i',{staticClass:"el-icon-arrow-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solve-text"},[_c('div',{staticClass:"solve-text1"},[_vm._v("自研课题")]),_c('div',{staticClass:"title-bottom"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solve-text"},[_c('div',{staticClass:"solve-text1"},[_vm._v("专利产权")]),_c('div',{staticClass:"title-bottom"})])
}]

export { render, staticRenderFns }