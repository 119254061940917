<template>
  <div>
    <div class="banenr-box">
      <img src="./assets/img/banner.png" alt />
    </div>
    <!-- 产品方案 -->
    <div class="solve-box">
      <div class="solve-text">
        <div class="solve-text1">产品方案</div>
        <div class="title-bottom"></div>
      </div>
      <div class="solve-action">
        <div class="scheme-box">
          <div class="scheme-prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="scheme-next">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in projectList" :key="index">
                <img :src="item.img" alt />
                <div class="swiper-slide-action">
                  <span class="text1">{{item.title}}</span>
                  <span class="text2">{{item.text}}</span>
                  <!-- <div class="swiper-slide-action-msg">
                    <span>{{item.time}}</span>
                    <span>了解更多&gt;</span>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 自研课题 -->
    <div class="solve-box">
      <div class="solve-text">
        <div class="solve-text1">自研课题</div>
        <div class="title-bottom"></div>
      </div>
      <div class="solve-action self-developed">
        <SelfDeveloped />
      </div>
    </div>
    <!-- 专利产权 -->
    <div class="solve-box">
      <div class="solve-text">
        <div class="solve-text1">专利产权</div>
        <div class="title-bottom"></div>
      </div>
      <div class="solve-action equity-box">
        <div class="equity-item" v-for="(item,index) in equityList" :key="index">
          <div class="equity-item-top">
            {{item.num}}
            <span>{{item.unit}}</span>
          </div>
          <div class="equity-item-bottom">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css";
import SelfDeveloped from "./components/SelfDeveloped.vue";
export default {
  components: { SelfDeveloped },
  data() {
    return {
      projectList: [
        {
          img: require("./assets/img/list/1.png"),
          title: "VR数智体教融合平台",
          text: `VR数智体教融合平台是以体育教育为核心，集资源整合、教育创新和技术应用于一体的综合性应用平台。将专业课程体系与VR、AI等前沿技术深度融合，打造沉浸学习体验。`,
          time: "2024.11.10"
        },
        {
          img: require("./assets/img/list/2.png"),
          title: "VST飞行仿真训练系统",
          text: `VST飞行仿真训练系统，融合视频透视技术，打造沉浸式飞行训练体验。系统模拟真实驾驶舱，结合高精度图像与数据反馈，提供如同真机般的操作手感与视觉效果。`,
          time: "2024.11.10"
        },
        {
          img: require("./assets/img/list/3.png"),
          title: "青研院虚拟现实开发引擎",
          text: `由国家虚拟现实创新中心（青岛）倾力打造的三维渲染引擎，成功突破实时光线追踪算法、云渲染等共性技术瓶颈，解决虚拟现实内容开发工具“卡脖子”难题，兼容国内外主流软硬件，实现技术自主掌控。`,
          time: "2024.11.10"
        }
      ],
      equityList: [
        {
          name: "国创中心（青岛）专利",
          unit: "(项)",
          num: "127"
        },
        {
          name: "联合股东单位专利",
          unit: "(余项)",
          num: "4000"
        },
        {
          name: "转件著作权",
          unit: "(项)",
          num: "85"
        }
      ]
    };
  },

  mounted() {
    this.getSwiper();
  },

  methods: {
    getSwiper() {
      this.mySwiper = new Swiper(".scheme-box .swiper-container", {
        autoplay: false,
        loop: true,
        paginationClickable: true,
        slidesPerView: 3,
        spaceBetween: "2%",
        navigation: {
          nextEl: ".scheme-next",
          prevEl: ".scheme-prev"
        },
        observer: true,
        observeParents: true
      });
    }
  }
};
</script>

<style scoped lang="css" src="./assets/css/index.css" />
