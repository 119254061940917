import Vue from 'vue'
import {
  baseUrl
} from "./config"
import router from '@/router'
import axios from "axios";

// 创建 axios 实例
const service = axios.create({
  baseURL: baseUrl,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    const foregroundToken = localStorage.getItem("foregroundToken");
    if (foregroundToken) {
      config.headers.Authorization = foregroundToken;
      config.headers.token = foregroundToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { code, message } = response.data;
    if (code == 1) {
      return response.data;
    }
    // 响应数据为二进制流处理(Excel导出)
    if (response.data instanceof ArrayBuffer) {
      return response;
    }
    if (code == 401) {
      Vue.prototype.$confirm('当前页面已失效，请重新登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const identity = localStorage.getItem("identity")
        router.push({ path: '/loginPage', query: { tab: identity == 'admin' ? 2 : 0 } });
        localStorage.setItem("foregroundToken", "")
        localStorage.setItem("identity", "")
      })
      return
    }
    Vue.prototype.$message({
      message: message || "系统出错",
      type: 'error'
    });
    return Promise.reject(new Error(message || "Error"));
  },
  (error) => {
    if (error.response.data) {
      const { code, message } = error.response.data;
      // token 过期,重新登录
      if (code == 401) {
        Vue.prototype.$confirm('当前页面已失效，请重新登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const identity = localStorage.getItem("identity")
          router.push({ path: '/loginPage', query: { tab: identity == 'admin' ? 2 : 0 } });
          localStorage.setItem("foregroundToken", "")
          localStorage.setItem("identity", "")
        })
      } else {
        Vue.prototype.$message({
          message: message || "系统出错",
          type: 'error'
        });
      }
    }
    return Promise.reject(error.message);
  }
);

// 导出 axios 实例
export default service;
