<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "@/utils/scroll";
import "@/utils/rem";
export default {
  name: "App"
};
</script>
<style lang="css" src="./assets/common.css" />
