import Vue from 'vue'
import App from './App.vue'

import router from './router'

import store from '@/store/index'

import ElementUI, { Message, MessageBox } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
import {
  baseUrl
} from "@/utils/config"
import '@/assets/font/font.css'
Vue.prototype.$baseUrl = baseUrl
import VueAMap from "vue-amap";
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  key: "731ac04c6e6e7bf4f72a10ce223f51b5", // 这里写你申请的高德地图的key
  plugin: ["AMap.Autocomplete", "AMap.Geocoder", "AMap.Geolocation"],
  v: "1.4.15",
  uiVersion: "1.1",
});

Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
