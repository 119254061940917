<template>
  <div>
    <div class="banner-box">
      <img class="banner" src="../assets/img/exploit/banner.png" alt />
      <div class="banner-text">
        <div class="banner-text1">
          集中解决“卡脖子”问题，
          完成关键节点的国产化替代
        </div>
        <div class="banner-text2">
          Concentrate on solving the "bottleneck" problem and
          complete the localization replacement of key nodes
        </div>
      </div>
      <div class="banner-logo">
        <img src="../assets/img/exploit/logo.png" alt />
      </div>
    </div>
    <div class="six-vessel">
      <div class="six-vessel-artificial"></div>
      <div
        class="six-item"
        v-for="(item,index) in sixList"
        :key="index"
        :style="index==sixList.length-1?'border:none':''"
      >
        <div class="six-item-action">
          <img :src="item.img" alt />
          <span>{{item.title}}</span>
        </div>
        <div class="six-item-hide">
          <div class="six-item-hide-diamond"></div>
          <div class="six-item-hide-text">{{item.text}}</div>
        </div>
      </div>
      <div class="six-vessel-artificial"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sixList: [
        {
          img: require("../assets/img/exploit/six/1.png"),
          title: "终端技术",
          text:
            "进行VR/AR整机终端的前瞻技术研究和产业化探索，将重点围绕芯片/电池等终端硬件优化、OS/应用等终端软件优化、易用性设计等技术开展研究。"
        },
        {
          img: require("../assets/img/exploit/six/2.png"),
          title: "图形运算",
          text:
            "针对多模态融合数据表示模型与实时仿真方法，在实时逼真绘制方法等方面展开突破，并集成、抽象和封装成工具链，形成渲染和计算的支撑平台。"
        },
        {
          img: require("../assets/img/exploit/six/3.png"),
          title: "人体工学",
          text:
            "进行VR/AR整机、外设的人体工学前瞻技术研究和产业化探索，将对新材料应用、热力学设计、人体数据标准化等技术开展重点研究。"
        },
        {
          img: require("../assets/img/exploit/six/4.png"),
          title: "光学显示",
          text:
            "解决光学性能提升、技术短板及压缩光学器件重量和体积等技术挑战，围绕AR显示模块、光波导量产技术VR光学系统等关键环节开展技术研究与攻关。"
        },
        {
          img: require("../assets/img/exploit/six/5.png"),
          title: "人机交互",
          text:
            "围绕沉漫声学、追踪定位技术、眼动追踪、手势识别、基于ToF相机的融合和应用技术，触觉反馈技术、三维传感技术展开研究。"
        },
        {
          img: require("../assets/img/exploit/six/6.png"),
          title: "通讯技术",
          text:
            "通信过程中的信息传输和信号处理 ，促进高速稳定网络支持，低延迟、数据安全，兼容等支持，助力广泛覆盖，以提供高质量的沉浸式通信体验。"
        }
      ]
    };
  },

  created() {},
  mounted() {},

  methods: {}
};
</script>

<style scoped lang="css">
.banner-box {
  position: relative;
  width: 100%;
  height: 806px;
  color: #fff;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner img {
  object-fit: cover;
}
.banner-text {
  position: absolute;
  top: 158px;
  left: 164px;
}
.banner-text1 {
  font-size: 46px;
  width: 552px;
  font-weight: 600;
}
.banner-text2 {
  font-size: 26px;
  width: 683px;
  margin-top: 37px;
}
.banner-logo {
  position: absolute;
  left: 85px;
  bottom: 87px;
  height: 94px;
}
.six-vessel {
  width: 100%;
  height: 870px;
  display: flex;
  background: url("../assets/img/exploit/six-bg.png");
  background-size: cover;
  background-position: center;
}
.six-vessel-artificial {
  width: 0.1px;
}
.six-item {
  width: calc(100% / 6);
  height: 100%;
  border-right: 1px solid #fff;
  transition: background 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.six-item-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.six-item-action img {
  width: 69px;
  height: auto;
}
.six-item-action > span {
  font-size: 30px;
  margin-top: 32px;
}

.six-item-hide {
  position: relative;
  height: 0;
  color: #fff;
  opacity: 0;
  transition: height 0.5s, opacity 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.six-item-hide-diamond {
  margin: 30px 0 40px;
  width: 88px;
  min-height: 3.5px;
  background-color: #fff;
}
.six-item-hide-text {
  padding: 0 50px;
  font-size: 16px;
  line-height: 28px;
}
.six-item:hover {
  /* background-color: #00000088; */
  background-color: #000;
}
.six-item:hover .six-item-hide {
  height: 250px;
  opacity: 1;
}
</style>
