<template>
  <div class="swiper-setting">
    <div class="swiper-setting-action" :class="`swiper-setting-action-${macklb}`">
      <div class="swiper-setting-action-item">
        <div class="first-module" @mouseleave="firstIndex=null">
          <div
            class="first-module-item"
            v-for="(item,index) in firstList"
            :key="index"
            @mouseenter="firstIndex=index"
          >
            <div
              class="first-module-small"
              :style="{opacity:firstIndex!=index&&firstIndex!=null?'1':'0',background:`url(${item.img}) no-repeat` }"
            >
              <div class="module-title">{{item.title}}</div>
              <div class="small-list">
                <img v-for="(imgItem,imgIndex) in item.small" :key="imgIndex" :src="imgItem" alt />
              </div>
            </div>
            <div
              class="first-module-centre"
              :style="{opacity:firstIndex==null?'1':'0',background:`url(${item.img}) no-repeat` }"
            >
              <div class="module-title">
                <span>{{item.title}}</span>
              </div>
              <div class="look-more">了解更多</div>
              <div class="module-flex"></div>
              <div class="module-msg-four" v-if="index==1">
                <div
                  class="module-msg-item"
                  v-for="(imgItem,imgIndex) in item.centre"
                  :key="imgIndex"
                >
                  <img :src="imgItem" alt />
                </div>
              </div>
              <div class="module-msg" v-else>
                <div
                  class="module-msg-item"
                  v-for="(imgItem,imgIndex) in item.centre"
                  :key="imgIndex"
                >
                  <img :src="imgItem" alt />
                </div>
              </div>
            </div>
            <div
              class="first-module-big"
              :style="{opacity:firstIndex==index?'1':'0',background:`url(${item.imgXuhua}) no-repeat` }"
            >
              <div class="module-title">
                <span>{{item.title}}</span>
                <img v-if="index==1" class="module-title-next" src="../assets/img/next.png" alt />
              </div>
              <div class="module-flex"></div>
              <div class="big-module-carousel" v-if="index==1">
                <div class="swiper-wrapper swiper-no-swiping">
                  <div class="swiper-slide" v-for="(bgItem,bgIndex) in item.bigBg" :key="bgIndex">
                    <div
                      class="big-module-action-item"
                      :style="{background:`url(${bgItem.img})`,width:'100%'}"
                    >
                      <div
                        class="big-module-title"
                        v-for="(textItem,textIndex) in bgItem.title"
                        :key="textIndex"
                      >{{textItem}}</div>
                      <div class="big-module-text">{{bgItem.text}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="big-module-action" v-else>
                <div
                  class="big-module-action-item"
                  v-for="(bgItem,bgIndex) in item.bigBg"
                  :key="bgIndex"
                  :style="{background:`url(${bgItem.img})`,width:item.bigBg.length==1?'100%':'49%'}"
                >
                  <div
                    class="big-module-title"
                    v-for="(textItem,textIndex) in bgItem.title"
                    :key="textIndex"
                  >{{textItem}}</div>
                  <div class="big-module-text">{{bgItem.text}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="first-module-mask" v-if="firstIndex==null">
            <div class="first-module-mask-item" @click="firstIndex=0"></div>
            <div class="first-module-mask-item" @click="firstIndex=1"></div>
            <div class="first-module-mask-item" @click="firstIndex=2"></div>
          </div>
        </div>
      </div>
      <div class="swiper-setting-action-item">
        <div class="second-module">
          <div class="second-module-item" v-for="(item,index) in secondList" :key="index">
            <img :src="item.img" alt />
            <div class="second-module-item-msg">
              <img :src="item.icon" alt />
              <span>{{item.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-prev-certificate" v-if="macklb=='right'" @click="macklb='left'">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="swiper-button-next-certificate" v-if="macklb=='left'" @click="macklb='right'">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      macklb: "left",
      firstList: [
        {
          title: "图形运算",
          img: require("../assets/img/developed/bg/1.png"),
          imgXuhua: require("../assets/img/developed/bg-xuhua/1.png"),
          small: [
            require("../assets/img/developed/small/1-1.png"),
            require("../assets/img/developed/small/1-2.png")
          ],
          centre: [
            require("../assets/img/developed/centre/1-1.png"),
            require("../assets/img/developed/centre/1-2.png")
          ],
          bigBg: [
            {
              title: ["自主可控虚拟现实", "开发/仿真工具链"],
              text:
                "进行VR/AR专用开发引擎及其底层渲染技术研究，以构建VR/AR专用开发引擎为核心进行研发",
              img: require("../assets/img/developed/big-bg/1-1.png")
            },
            {
              title: ["云渲染", "系统的研究"],
              text:
                "研究混合实时渲染技术、实时云渲染和串流技术、云渲染部署和管理技术、支持分布式及集群部署的云渲染技术，开发国产化引擎适配模块",
              img: require("../assets/img/developed/big-bg/1-2.png")
            }
          ]
        },
        {
          title: "终端技术",
          img: require("../assets/img/developed/bg/2.png"),
          imgXuhua: require("../assets/img/developed/bg-xuhua/2.png"),
          small: [
            require("../assets/img/developed/small/2-1.png"),
            require("../assets/img/developed/small/2-2.png"),
            require("../assets/img/developed/small/2-3.png"),
            require("../assets/img/developed/small/2-4.png")
          ],
          centre: [
            require("../assets/img/developed/centre/2-1.png"),
            require("../assets/img/developed/centre/2-2.png"),
            require("../assets/img/developed/centre/2-3.png"),
            require("../assets/img/developed/centre/2-4.png")
          ],
          bigBg: [
            {
              title: ["舒适性设计"],
              text:
                "以虚拟现实设备的小型化、轻量化目标，创新中心将围绕新材料方面展开研发。",
              img: require("../assets/img/developed/big-bg/2-1.png")
            },
            {
              title: ["视频透视系统"],
              text:
                "研究视频透视核心技术与系统制造方面实现原理性创新，并实现关键领域的应用示范。",
              img: require("../assets/img/developed/big-bg/2-2.png")
            },
            {
              title: ["终端功耗优化"],
              text:
                "研究掌握功耗优化技术，优化产品功耗性能，挖掘平台功耗边界，提升技术能力；掌握AR典型场景功耗拆解，优化，策略定制化方案，评测标准等技术。",
              img: require("../assets/img/developed/big-bg/2-3.png")
            },
            {
              title: ["实时光线追踪算法的研究"],
              text:
                "研究基于PBR的渲染系统、全局光照技术、支持光线追踪硬件加速的渲染系统。",
              img: require("../assets/img/developed/big-bg/2-4.png")
            }
          ]
        },
        {
          title: "沉浸声学",
          img: require("../assets/img/developed/bg/3.png"),
          imgXuhua: require("../assets/img/developed/bg-xuhua/3.png"),
          small: [require("../assets/img/developed/small/3-1.png")],
          centre: [require("../assets/img/developed/centre/3-1.png")],
          bigBg: [
            {
              title: ["XR沉浸声学"],
              text:
                "在声学算法、声学硬件结构设计、声学整体解决方案、声学检测标准等方面展开研发，形成一系列XR声学解决方案，同时针对VR、AR不同的应用场景，提出专用的定制解决方案。 ",
              img: require("../assets/img/developed/big-bg/3-1.png")
            }
          ]
        }
      ],
      firstIndex: null,
      secondList: [
        {
          img: require("../assets/img/developed/second/1.png"),
          icon: require("../assets/img/developed/second-icon/1.png"),
          text: "人体工学"
        },
        {
          img: require("../assets/img/developed/second/2.png"),
          icon: require("../assets/img/developed/second-icon/2.png"),
          text: "人机交互"
        },
        {
          img: require("../assets/img/developed/second/3.png"),
          icon: require("../assets/img/developed/second-icon/3.png"),
          text: "光学显示"
        }
      ]
    };
  },

  created() {},
  mounted() {
    this.getSwiper();
  },

  methods: {
    getSwiper() {
      new Swiper(".big-module-carousel", {
        autoplay: false,
        loop: true,
        paginationClickable: true,
        slidesPerView: 2, // 一组1个
        spaceBetween: "1.8%", // 间隔
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".module-title-next"
        },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true
      });
    }
  }
};
</script>

<style scoped lang="css">
.swiper-setting {
  width: 100%;
  height: 900px;
  position: relative;
  overflow: hidden;
}

.swiper-setting-action {
  position: absolute;
  right: 0;
  width: 200%;
  height: 100%;
  display: flex;
  transition: left 0.3s;
}
.swiper-setting-action-left {
  left: 0;
}
.swiper-setting-action-right {
  left: -100%;
}
.swiper-setting-action-item {
  width: 50%;
  height: 100%;
}
.swiper-button-next-certificate {
  top: calc(50% - 30px);
  right: 10px;
}
.swiper-button-prev-certificate {
  top: calc(50% - 30px);
  left: 10px;
}
.swiper-button-next-certificate,
.swiper-button-prev-certificate {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 4px solid #d6d6d6;
  border-radius: 50%;
  color: #8bb652;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.swiper-button-next-certificate:hover,
.swiper-button-prev-certificate:hover {
  transform: scale(1.01);
}

.swiper-button-next-certificate:active,
.swiper-button-prev-certificate:active {
  transform: scale(0.99);
}

.swiper-setting .swiper-slide {
  width: 100%;
  height: 100%;
}
.first-module {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.first-module-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
}
.first-module-mask-item {
  width: calc(100% / 3);
  height: 100%;
}
.first-module-item {
  flex: 1;
  height: 100%;
  transition: flex 0.3s;
  position: relative;
}
.first-module-item:hover {
  flex: 2.5;
}
.first-module-small,
.first-module-centre,
.first-module-big {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s;
  background-size: cover !important;
  background-position: center center !important;
}
.first-module-small {
  padding: 91px 29px 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.small-list {
  margin-top: 34px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.small-list img {
  /* height: auto; */
  width: auto;
  height: 145px;
  margin-bottom: 25px;
}
.first-module-centre {
  padding: 91px 29px 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.look-more {
  width: 138px;
  height: 41px;
  background-color: #00000049;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 40px;
}
.module-flex {
  flex: 1;
}
.module-msg-four {
  width: 394px;
  height: 320px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.module-msg-four img {
  height: 150px;
}
.module-msg {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
}
.module-msg-item {
  margin: 0 auto;
}
.first-module-big {
  background-color: green;
  padding: 91px 144px 42px;
  display: flex;
  flex-direction: column;
}
.module-title {
  font-size: 40px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.module-title img {
  width: 57px;
  height: 57px;
  cursor: pointer;
}
.big-module-carousel {
  width: 778.67px;
  height: 680px;
  position: relative;
  overflow: hidden;
  padding: 0;
}

.big-module-action {
  width: 778.67px;
  height: 680px;
  display: flex;
  justify-content: space-between;
}
.big-module-action-item {
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  padding: 73px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.big-module-title {
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 1s;
  transition-delay: 0.3s;
}
.big-module-text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 24px;
  color: #444;
  opacity: 0;
  transition: opacity 1s;
  transition-delay: 0.3s;
}
.big-module-add {
  margin-top: 15px;
  font-size: 30px;
  color: #555;
  opacity: 0;
  transition: opacity 1s;
  transition-delay: 0.3s;
}
.first-module-item:hover .big-module-title,
.first-module-item:hover .big-module-text,
.first-module-item:hover .big-module-add {
  opacity: 1;
}
.second-module {
  width: 100%;
  height: 100%;
  display: flex;
}
.second-module-item {
  width: calc(100% / 3);
  height: 100%;
  position: relative;
  overflow: hidden;
}
.second-module-item > img {
  object-fit: cover;
  transition: transform 0.3s;
}
.second-module-item-msg {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 50px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
}
.second-module-item-msg img {
  width: auto;
  height: 81px;
  margin-bottom: 20px;
}
.second-module-item:hover > img {
  transform: scale(1.1);
}
</style>
