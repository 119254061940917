<template>
  <div class="page-box">
    <div class="nav-title-box" :class="menuSkin=='lucency'?'nav-title-box-white':''">
      <div class="nav-title">
        <div class="nav-title-logo">
          <img :src="require(`./assets/img/logo${menuSkin=='lucency'?'-black':''}.png`)" alt />
        </div>
        <div class="nav-title-action">
          <div class="nav-title-jump">
            <div
              class="nav-title-jump-item"
              v-for="(item,index) in tabbar_list"
              :key="index"
              @mouseenter="handleMouseEnter(item.title)"
              @mouseleave="handleMouseLeave()"
              @click="jumpRouter(item.path)"
            >
              <span>{{item.title}}</span>
              <div
                class="nav-title-jump-item-select"
                :style="{width:item.path==selfPath?'80%':'0%'}"
              ></div>
            </div>
          </div>
          <!-- 鼠标悬浮展示菜单 -->
          <div class="secondlevel-box" @mouseleave="handleMouseLeave()">
            <div
              class="secondlevel-action"
              :class="hoverTitle==null?'':'secondlevel-action-change'"
            >
              <div class="secondlevel-action-menu">
                <div class="secondlevel-action-menu-item" @click="jumpSix(0)">
                  <div class="secondlevel-action-menu-title">共性技术研发平台</div>
                  <div class="secondlevel-action-menu-text">六大方向</div>
                </div>
                <div class="secondlevel-action-menu-item" @click="jumpSix(1)">
                  <div class="secondlevel-action-menu-title">检测平台</div>
                  <div class="secondlevel-action-menu-text">检测服务</div>
                  <div class="secondlevel-action-menu-text">八大实验室</div>
                  <div class="secondlevel-action-menu-text">检测成果</div>
                </div>
                <div class="secondlevel-action-menu-item" @click="jumpSix(2)">
                  <div class="secondlevel-action-menu-title">中试平台</div>
                  <div class="secondlevel-action-menu-text">场地规模</div>
                  <div class="secondlevel-action-menu-text">生产能力</div>
                  <div class="secondlevel-action-menu-text">测试能力</div>
                </div>
                <div class="secondlevel-action-menu-item" @click="jumpSix(3)">
                  <div class="secondlevel-action-menu-title">技术服务平台</div>
                  <div class="secondlevel-action-menu-text">技术开发服务</div>
                  <div class="secondlevel-action-menu-text">技术咨询服务</div>
                  <div class="secondlevel-action-menu-text">大型仪器设备开放共享服务</div>
                  <div class="secondlevel-action-menu-text">行业应用解决方案</div>
                </div>
                <div class="secondlevel-action-menu-item" @click="jumpSix(4)">
                  <div class="secondlevel-action-menu-title">人才培养平台</div>
                  <div class="secondlevel-action-menu-text">培才动态</div>
                </div>
                <div class="secondlevel-action-menu-item" @click="jumpSix(5)">
                  <div class="secondlevel-action-menu-title">国际交流与合作平台</div>
                  <div class="secondlevel-action-menu-text">交流活动</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="nav-title-utils">
            <img
              class="tu1"
              :src="require(`./assets/img/search${menuSkin=='lucency'?'-black':''}.png`)"
              alt
            />
            <span>|</span>
            <img
              class="tu2"
              :src="require(`./assets/img/translate/${translateType}.png`)"
              @click="translateType=(translateType==1?2:1)"
            />
          </div>-->
        </div>
      </div>
    </div>
    <router-view :key="$route.path+$route.query.id"></router-view>

    <div class="util-list">
      <div class="util-list-item" @click="jumpRouter('/contactPage')">
        <i class="el-icon-message"></i>
      </div>
      <div class="util-list-item" @click="copyText()">
        <i class="el-icon-share"></i>
        <input id="input" value="这是幕后黑手" />
      </div>
      <div class="util-list-item" @click="goTop()">
        <i class="el-icon-upload2"></i>
      </div>
    </div>

    <div class="nav-footer">
      <div class="common-container nav-footer-action">
        <div class="nav-footer-left">
          <div class="nav-footer-left-item" v-for="(item,index) in footer_msg" :key="index">
            <div class="nav-footer-left-title">{{item.title}}</div>
            <div
              class="nav-footer-left-text"
              v-for="(item2,index2) in item.list"
              :key="index2"
            >{{item2.name}}</div>
          </div>
        </div>
        <div class="nav-footer-right">
          <div class="nav-footer-left-item">
            <div class="nav-footer-left-title">关于我们</div>
            <div class="nav-footer-left-code">
              <img src="./assets/img/code.png" alt />
            </div>
          </div>
          <div class="connection">
            <span>联系地址:山东省青岛市崂山区株洲路170号</span>
            <span>联系邮箱:qvri.pub@qdvri.com</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      selfPath: "",
      tabbar_list: [
        {
          title: "首页",
          path: "/indexPage"
        },
        {
          title: "关于我们",
          path: "/aboutPage"
        },
        {
          title: "创新生态",
          path: "/ecologyPage"
        },
        {
          title: "创新服务",
          path: "/serverPage"
        },
        {
          title: "创新成果",
          path: "/achievementPage"
        },
        {
          title: "新闻动态",
          path: "/newPage"
        },
        {
          title: "联系我们",
          path: "/contactPage"
        }
      ],
      footer_msg: [
        {
          title: "关于我们",
          list: [
            { name: "中心简介" },
            { name: "六大平台" },
            { name: "组织架构" },
            { name: "发展历程" }
          ]
        },
        {
          title: "创新生态",
          list: [
            { name: "专家团队" },
            { name: "科研合作" },
            { name: "平台资质" },
            { name: "企业孵化" },
            { name: "合作联盟" }
          ]
        },
        {
          title: "创新服务",
          list: [
            { name: "共性技术研发平台" },
            { name: "检测平台" },
            { name: "中试平台" },
            { name: "技术服务平台" },
            { name: "人才培养平台" },
            { name: "国际交流与合作平台" }
          ]
        },
        {
          title: "创新成果",
          list: [
            { name: "产品方案" },
            { name: "自研课题" },
            { name: "产品专利" }
          ]
        },
        {
          title: "新闻动态",
          list: [
            { name: "中心动态" },
            { name: "领导关怀" },
            { name: "通知公告" }
          ]
        },
        {
          title: "联系我们",
          list: [{ name: "商业咨询" }, { name: "人才招聘" }]
        }
      ],
      hoverTitle: null,
      menuSkin: "none",
      translateType: 1
    };
  },
  created() {},
  mounted() {
    this.selfPath = this.$route.path;
    if (this.selfPath == "/indexPage") {
      this.navAnimation();
    }
  },
  beforeDestroy() {},
  computed: {
    ...mapState(["clientTop"]),
    whetherNav() {
      return (
        this.selfPath == "/ecologyPage" ||
        this.selfPath == "/achievementPage" ||
        this.selfPath == "/newPage" ||
        this.selfPath == "/newDetailPage" ||
        this.selfPath == "/contactPage"
      );
    }
  },
  watch: {
    $route(to, from) {
      this.selfPath = to.path;
      if (to.path != from.path) {
        this.menuSkin = this.whetherNav ? "lucency" : "none";
        // if (from.path == "/indexPage") {
        //   this.navAnimation();
        // }
      }
    },
    clientTop(newVal) {
      this.handleScroll(newVal);
    }
  },
  methods: {
    navAnimation() {
      const navElement = document.querySelector(".nav-title-box");
      navElement.style.animation = "navAnim 1s both";
      navElement.style.animationDelay = "6s";
      // 视频8秒
    },
    handleMouseEnter(title) {
      this.hoverTitle = title == "创新服务" ? title : null;
    },
    handleMouseLeave() {
      var el = window.document.body;
      window.document.body.onmouseover = event => {
        el = event.target._prevClass;
        if (!el || !el.includes("secondlevel-action-menu")) {
          this.hoverTitle = null;
          window.document.body.onmouseover = null;
        }
      };
    },
    jumpSix(index) {
      const r = this.$route.path;
      this.$router.push({ path: "/serverPage", query: { menuIndex: index } });
      if (r == "/serverPage") {
        window.location.reload();
      }
    },
    jumpRouter(path) {
      this.$router.push({ path });
      this.selfPath = path;
      window.scrollTo(0, 0);
    },
    handleScroll(scrollTop) {
      if (scrollTop >= 200) {
        this.menuSkin = "lucency";
      } else {
        this.menuSkin = this.whetherNav ? "lucency" : "none";
      }
    },
    goTop() {
      window.scrollTo(0, 0);
    },
    copyText() {
      const input = document.getElementById("input"); // 承载复制内容
      input.value = window.location.href; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制
      this.$message({
        message: "网页链接已复制到剪切板",
        type: "success",
        customClass: "messageIndex"
      });
    }
  }
};
</script>
<style>
@keyframes navAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
<style scoped lang="css" src="./assets/css/index.css" />