<template>
  <div id="list_box">
    <!-- <div class="list" @mouseenter="stopAn" @mouseleave="leaveAn"> -->
    <div class="list">
      <ul id="list1" ref="list1">
        <li v-for="(item,index) in list" :key="index">
          <img :src="item" alt />
        </li>
      </ul>
      <ul ref="list2" v-show="scroll_switch">
        <li v-for="(item,index) in list" :key="index">
          <img :src="item" alt />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "horseList",
  props: ["list"],
  data() {
    return {
      scroll_switch: false
    };
  },
  mounted() {
    this.stopAn();
    const h1 = document.getElementById("list_box").clientHeight;
    const h2 = document.getElementById("list1").clientHeight;
    this.scroll_switch = h1 < h2;
    if (this.scroll_switch) {
      this.leaveAn();
    } else {
      this.stopAn();
    }
  },
  methods: {
    // 接着动画
    leaveAn() {
      if (this.scroll_switch) {
        this.$refs.list1.style.animationDuration = `${this.list.length * 5}s`;
        this.$refs.list2.style.animationDuration = `${this.list.length * 5}s`;
        this.$refs.list1.style.animationPlayState = "running";
        this.$refs.list2.style.animationPlayState = "running";
      } else {
        console.log("长度不够！！！不能滚动");
      }
    },
    // 暂停动画
    stopAn() {
      this.$refs.list1.style.animationPlayState = "paused";
      this.$refs.list2.style.animationPlayState = "paused";
    }
  }
};
</script>
<style scoped lang="scss">
#list_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #ffffffd0;
  // transform: rotate(180deg);
  .list {
    ul {
      animation: 10s move infinite linear; //动画开始
      li {
        // transform: rotate(180deg);
        padding: 10px 0;
        cursor: pointer;
        text-align: justify;
        img {
          height: 222px;
          object-fit: cover;
        }
      }
    }
    @keyframes move {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(0, -100%);
      }
    }
  }
}
</style>
    